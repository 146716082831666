import { ImportFile } from './import-file/import-file';
import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, ActionDialogBoxInputParameters, DialogBoxViewModel, EnumerationTypeService, IMenuGroup, PictureHelper, ShowEditDialogOptions, GlobalLoaderService, IFile } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine } from 'aurelia-framework';
import * as Constants from '../constants';
import { SupplierOfferApiService } from 'services/supplier-offer-api-service';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { IDataLineApiService, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { PriceOfferLineCategory, PriceOfferLineMoveAction } from '../constants';
import { AddItems } from 'projects/quotes/add-items/add-items';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { PriceRepartition } from 'projects/quotes/price-repartition/price-repartition';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { QuillDialog } from 'quill-dialog/quill-dialog';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { UpdateItemType } from 'projects/quotes/update-items/update-items';
import { SupplierOfferMenuItem } from './supplier-offer-menu-item';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';

@autoinject
export class SupplierOfferDetail extends EntityDetailViewModelBase<Merlin.Web.Model.SupplierOffer> {
  public documentTitle = "";
  public ressourceName = Constants.EntityTypeNames.SupplierOffer
  public titlePage: string;
  public Constants: any = Constants;
  public priceOfferLinesGrid: PriceOfferLinesGrid;

  public requestToSupplierId: number;
  private menuItems: Array<IMenuGroup>;
  public requestToSupplierService: ServiceBase<Merlin.Web.Model.RequestToSupplier>;
  public requestToSupplier: Merlin.Web.Model.RequestToSupplier;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  private footer: MeteringTotalPrices;
  private screenExpand: boolean = false;

  private notCanEditSupplierOffer: boolean = false;
  private projectService: ServiceBase<Merlin.Web.Model.Project>




  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }
  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: true },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "originalProposedQuantity", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: false },
      { colId: "proposedQuantityFormula", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: false },
      { colId: "requestedQuantity", visibility: false },
      { colId: "dataLineDeliveries", visibility: false },
      { colId: "dataLineActivities", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "definedSellingUnitPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "totalBuyingPrice", visibility: false },
      { colId: "totalSellingPrice", visibility: false },
      { colId: "tags", visibility: false },
      { colId: "features", visibility: true },
      { colId: "supplierPrice", visibility: true },
      { colId: "supplierQuantity", visibility: true },
      { colId: "supplierQuantityTypeId", visibility: true },
      { colId: "supplierTotal", visibility: true },
      { colId: "supplierComment", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "diffIndirectCostFromPAR", visibility: false },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "indirectCostFromPAR", visibility: false },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "buildingCostsId", visibility: true },

      { colId: "estimatedQuantity", visibility: true },
      { colId: "estimatedBuyingUnitPrice", visibility: true },
      { colId: "estimatedTotalBuyingPrice", visibility: true },
      { colId: "estimatedComment", visibility: true },
    ]

  type: DocumentTypeDiscriminator;
  projectId: number;
  supplierOfferId: number;
  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private bindingEngine: BindingEngine, private dialogController: DialogController, private supplierOfferVersionApiService: SupplierOfferApiService, private fileHelper: PictureHelper, private projectApiService: ProjectApiService, private globalLoaderService: GlobalLoaderService,
    private meteringMenuItems: MeteringMenuItems, private supplierOfferMenuItem: SupplierOfferMenuItem) {
    super(router, logger);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);

  }

  public async activate(params) {
    let id = params.supplierOfferId;
    this.supplierOfferId = id;
    this.projectId = params.projectId;
    this.type = params.type;
    this.requestToSupplierId = params.requestToSupplierId;
    let requestOfferTypeId: number;

    this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.RequestToSupplier>(Constants.EntityTypeNames.RequestToSupplier);

    if (this.type == DocumentTypeDiscriminator.WorkQuotationVersion) {
      this.requestToSupplier = await this.requestToSupplierService.getEntityById(this.requestToSupplierId, 'requestSupplierOffer', 'toSupplierOrSubContractor');
      requestOfferTypeId = this.requestToSupplier.requestSupplierOffer.requestOfferTypeId;
    } else if (this.type == DocumentTypeDiscriminator.Buying) {
      this.requestToSupplier = await this.requestToSupplierService.getEntityById(this.requestToSupplierId, 'requestSupplierOffer', 'toSupplierOrSubContractor');
      requestOfferTypeId = this.requestToSupplier.requestSupplierOffer.requestOfferTypeId;
    }
    super.initialize(new ServiceBase<Merlin.Web.Model.SupplierOffer>(Constants.EntityTypeNames.SupplierOffer));
    this.supplierOfferVersionApiService.type = this.type;

    this.titlePage = this.i18n.tr("requesttosupplier.requesttosupplier") + ' : ' + this.requestToSupplier.toSupplierOrSubContractor.fullName

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (requestOfferTypeId == Constants.SupplierOfferTypeId.ForPriceOffer) {
        this.entity.offerTypeId = Constants.SupplierOfferTypeId.ForPriceOffer;
      } else if (requestOfferTypeId == Constants.SupplierOfferTypeId.ForWorkingSite) {
        this.entity.offerTypeId = Constants.SupplierOfferTypeId.ForWorkingSite;
      }
      this.entity.receivedDateTime = new Date();
      this.entity.requestToSupplierId = this.requestToSupplierId;

      this.entity.supplierOrSubContractorId = this.requestToSupplier.toSupplierOrSubContractorId;
      this.entity.source = await (new EnumerationTypeService(Constants.EnumerationTypes.SubcontractorOfferSource)).getAnyByUniqueCode("Manual");
      this.entity.sourceId = Constants.SubcontractorOfferSourceId.Manual;
    } else {
      this.editingMode = EditingModeEnum.Update;

      await this.reloadSupplierOffer(id);
      await this.getMeteringTotalPrices();
      if (this.entity.offerTypeId == Constants.SupplierOfferTypeId.ForWorkingSite) {
        this.columnVisible.find(x => x.colId == "originalProposedQuantity").visibility = false;
        this.columnVisible.find(x => x.colId == "supplierQuantity").visibility = true;
        this.columnVisible.find(x => x.colId == "supplierQuantity").overideHeaderValueGetter = () => this.i18n.tr("supplierofferline.supplierQuantityForWorkingSite"),
          this.columnVisible.find(x => x.colId == "supplierQuantityTypeId").visibility = false;
        this.columnVisible.find(x => x.colId == "acceptedPriceOfferBuyingUnitPrice").visibility = true;
      } else {
        this.columnVisible.find(x => x.colId == "supplierQuantity").overideHeaderValueGetter = () => this.i18n.tr("supplierofferline.supplierQuantity");

      }

      if (this.type == DocumentTypeDiscriminator.Buying) {
        let p1 = new Predicate("supplierOfferId", FilterQueryOp.Equals, this.entity.id);
        let p2 = new Predicate("thirdPartyContractStatusId", FilterQueryOp.Equals, Constants.ThirdPartyContractStatusId.InProcess);
        let p3 = new Predicate("thirdPartyContractStatusId", FilterQueryOp.Equals, Constants.ThirdPartyContractStatusId.Validated);
        let count = await (new ServiceBase<Merlin.Web.Model.ThirdPartyContract>(Constants.EntityTypeNames.ThirdPartyContract)).getCount(p1.and(p2.or(p3)));
        this.notCanEditSupplierOffer = count > 0;
      }
    }
    this.setMenuItems();
    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;

  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("supplieroffer.importXls"),
            icon: "digi-file-download-line",
            hidden: () => this.isCreationMode,
            handler: async () => {
              await this.importSupplierOffer();
            }
          },
          {
            label: this.i18n.tr("supplieroffer.supplierOfferFileDownload"),
            icon: "digi-download",
            hidden: () => this.isCreationMode,
            disabled: () => this.entity.supplierOfferFile == null,
            handler: async () => {
              await this.downloadSupplierOfferFile();
            }
          },
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-delete-bin-line",
            handler: async () => {
              await this.deleteSupplierOffer();
            }
          }
        ]
      },
    ];
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return this.supplierOfferMenuItem.getSupplierOfferMenuItem(params, this.priceOfferLinesGrid, this.notCanModifiedSupplierOffer(), this.supplierOfferVersionApiService, this.entity.requestToSupplierId, this.computeMeteringTotalPrice, this)
  }


  private notCanModifiedSupplierOffer() {
    if (this.type == DocumentTypeDiscriminator.WorkQuotationVersion) {
      return false;
    }
    if (this.type == DocumentTypeDiscriminator.Buying) {
      return this.notCanEditSupplierOffer;
    }
    return true;

  }



  async importSupplierOffer() {
    await this.box.showEditDialog(ImportFile, this.entity.id, this.i18n.tr("supplieroffer.importXls"), {
      size: 'md',
      canSave: false,
      model: {
        type: this.type
      },
      actions: [
        EditDialogAction.GetCancelAction(this.i18n),
        {
          label: this.i18n.tr("importmetering.import"),
          title: this.i18n.tr("importmetering.import"),
          theme: "primary",
          type: "solid",
          closeDialog: false,
          fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
            let imported = await dialogBoxViewModel.customVM.currentViewModel.import();
            if (imported) {
              dialogBoxViewModel.controller.ok();
            }
          }
        } as ActionDialogBoxInputParameters
      ]
    } as ShowEditDialogOptions).whenClosed(async x => {
      if (!x.wasCancelled) {
        //Load entity
        await this.reloadSupplierOffer();
        await this.getMeteringTotalPrices();
        this.priceOfferLinesGrid.refreshServerSideRows([], true);
      }
    })
  }

  public async deleteSupplierOffer() {
    let responseCanDelete = await this.httpClient.get(Constants.Application.SupplierOfferController.CanDelete.format(this.type.toString(), this.entity.id.toString()));
    if (responseCanDelete.ok) {
      let canDelete = await responseCanDelete.json()
      if (canDelete) {
        let buttonYes: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.yes", { ns: "common" }),
          title: this.i18n.tr("general.yes", { ns: "common" }),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(true);
          }
        };
        let buttonNo: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.no", { ns: "common" }),
          title: this.i18n.tr("general.no", { ns: "common" }),
          theme: 'dark',
          type: 'ghost',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(false);
          }
        };
        await this.box.showQuestion(this.i18n.tr('supplieroffer.confirmDelete'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
          async (result) => {
            if (!result.wasCancelled) {
              if (result.output == true) {
                let response = await this.httpClient.delete(Constants.Application.SupplierOfferController.Delete.format(this.type.toString(), this.entity.id.toString()));
                if (response.ok) {
                  this.router.navigateBack();
                }
              }
            }
          }
        )
      } else {
        this.box.showWarning(this.i18n.tr('supplieroffer.notCanDelete'), this.i18n.tr('general.warningTitle'));
        return [];
      }
    }
  }

  private async reloadSupplierOffer(id?: number) {
    this.entity = await this.service.getEntityById(this.entity?.id ?? id, 'source', 'supplierOfferFile');
  }

  public async save() {
    await super.save();
  }


  public canEditCell(line: any, colField: string): boolean {
    if (line == null || colField == null) return false;

    switch (colField) {
      case "unitId":
      case "quantityTypeId":
      case "unitQuantityDetailItemComposed":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
      case "supplierPrice":
      case "supplierTotal":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && !line.hasChildrenSubItem;
      case "supplierQuantity":
      case "supplierQuantityTypeId":
      case "supplierQuantityTypeTranslatedString":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && this.type != DocumentTypeDiscriminator.Buying;
      case "supplierComment":
        return true;
      case "estimatedBuyingUnitPrice":
      case "estimatedTotalBuyingPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && !line.hasChildrenSubItem;
      case "estimatedQuantity":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && this.type != DocumentTypeDiscriminator.Buying;
      case "estimatedComment":
        return true;
      default:
        return true;
    }

  }
  public async priceOfferLinesGridCellValueChanged(colField: string) {
    /*if (colField == "supplierPrice" || colField == "supplierQuantity" || colField == "supplierQuantityTypeId") {
      let result = await this.supplierOfferVersionApiService.patchSource(this.entity.id, Constants.SubcontractorOfferSourceId.Manual);
      if (result) {
        await this.reloadSupplierOffer();
      }
    }*/
    await this.getMeteringTotalPrices();
  }

  private async getMeteringTotalPrices() {
    if (this == null) return;
    this.footer = await this.supplierOfferVersionApiService.getMeteringTotalPrices(this.entity.id);
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  downloadSupplierOfferFile() {
    this.fileHelper.downloadFile(this.entity.supplierOfferFile);
  }

  public async computeMeteringTotalPrice(dataLineApiService: IDataLineApiService, _this: any) {
    _this.footer = await dataLineApiService.getMeteringTotalPrices(parseInt(_this.supplierOfferId));
  }

  public async getExcelFileName() {
    let title = (await this.projectService.getEntityById(this.projectId)).projectName;
    if (this.type == DocumentTypeDiscriminator.WorkQuotationVersion) {
      let version = (this.requestToSupplier.requestSupplierOffer as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersion
      title += "_" + this.i18n.tr("projectindex.quote") + '_' + "V." + version.quotationVersionNum + '_' + version.quotationVersionName;
    }
    else {
      title += "_" + this.i18n.tr("projectindex.buying");
    }

    title += "_" + this.i18n.tr("requestsupplieroffer.requestsupplieroffers");
    title += '_' + this.requestToSupplier.requestSupplierOffer.requestOfferID;

    return title + '_' + this.requestToSupplier.toSupplierOrSubContractor.fullName
  }
}
