import { EventAggregator } from 'aurelia-event-aggregator';
import { ICellRendererParams } from "ag-grid-community";
import { DialogService } from "aurelia-dialog";
import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel, EnumerationType, GlobalLoaderService, IMenuGroup, IMenuItems, KeyboardShortcut, ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import { BuyingCommonMetering } from "projects/metering/buying-common-metering";
import { ClientProgressStatementApiService, SubContractorProgressStatementLineApiService } from "services/progress-statement-api-service";
import { ProjectApiService } from "services/project-api-service";
import * as Constants from '../../../constants';
import { Router } from 'aurelia-router';
import { ColumnVisible } from "module-list-tree-data/module-list-tree-data";
import { MeteringTotalPrices, ProgressStatementTotal } from "services/i-data-line-api-service";
import { DataFormat } from "select2";
import { Predicate, FilterQueryOp } from 'breeze-client';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';

@autoinject
export class ProgressStatementSubContractor extends BuyingCommonMetering {
  public listIsFlat: boolean = false;
  ribbonHeaderText = this.i18n.tr("realizationindex.subContractorProgressStatementInProgress");
  public footer: ProgressStatementTotal;
  public buyingAdditionalWorkTypeList: Array<EnumerationType>;
  public progressStatementService: ServiceBase<Merlin.Web.Model.ProgressStatement>;
  menuItems: IMenuItems[];
  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number
  private canCreateProgressStatement: boolean = false;
  private agGridViewModuleView = "sub-contractor-progress-statement";
  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: false },
      { colId: "proposedQuantityFormula", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: false },
      { colId: "requestedQuantity", visibility: false },
      { colId: "dataLineDeliveries", visibility: false },
      { colId: "dataLineActivities", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "definedSellingUnitPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "totalBuyingPrice", visibility: false },
      { colId: "totalSellingPrice", visibility: false },
      { colId: "tags", visibility: false },
      { colId: "supplierPrice", visibility: true },
      { colId: "supplierQuantity", visibility: true },
      { colId: "supplierQuantityTypeId", visibility: true },
      { colId: "supplierTotal", visibility: true },
      { colId: "deltaTotal", visibility: true },
      { colId: "absoluteTotal", visibility: true },
      { colId: "supplierComment", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "totalQuantityDone", visibility: true },
      { colId: "totalQuantityPercentageDone", visibility: true },
      { colId: "isFinalised", visibility: true },
      { colId: "deltaQuantityDone", visibility: true },
      { colId: "deltaQuantityDonePercentage", visibility: true },
      { colId: "absoluteQuantityDone", visibility: true },
      { colId: "absoluteQuantityDonePercentage", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];

  @observable
  public subContractorSelected: DataFormat | number = null;

  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  public showGrid: boolean = false;

  constructor(public ea: EventAggregator, public progressStatementLineApiService: SubContractorProgressStatementLineApiService, public progressStatementApiService: ClientProgressStatementApiService, public i18n: I18N, box: Box, private globalLoaderService: GlobalLoaderService, dialogService: DialogService, router: Router, private projectApiService: ProjectApiService, meteringMenuItems: MeteringMenuItems) {
    super(dialogService, box, progressStatementLineApiService, router, meteringMenuItems);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);

    ea.subscribe('router:navigation:complete', (event) => {
      if (!this.showGrid && this.progressStatementLineApiService.subContractorId != null) {
        setTimeout(() => {
          this.showGrid = true;
          setTimeout(async () => {
            await this.priceOfferLinesGrid.listTreeData.setAgGridView();
          }, 1);
        }, 1);
      }
    })
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.progressStatementLineApiService.projectId = this.projectId;
    await this.getMeteringTotalPrices();
    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;
    this.thirdPartyService.gridDataSource.queryParameters = { projectId: this.projectId }
    this.setHeaderMenuItems();
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return []
  }
  async getMeteringTotalPrices() {
    if (this.projectId != null && this.subContractorSelected != null)
      this.footer = await this.progressStatementLineApiService.getTotals(this.projectId, this.subContractorSelected as number);
    else
      return null;
  }
  canEditCell(line: any, colField: any) {
    switch (colField) {
      case "deltaQuantityDone":
      case "absoluteQuantityDone":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;

      case "absoluteQuantityDonePercentage":
      case "deltaQuantityDonePercentage":
        return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment
      default:
        return false;
    }
  }
  async deactivate() {
    //this.progressStatementLineApiService.subContractorId = null
    await this.priceOfferLinesGrid?.deactivate()
  }

  private setHeaderMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("progressstatement.save"),
            icon: "digi-save",
            disabled: () => { return this.subContractorSelected == null },
            handler: async () => {
              await this.createProgressStatement();
            }
          },
          {
            label: this.i18n.tr("progressstatement.synchroWithClient"),
            icon: "digi-synchronization",
            disabled: () => { return this.subContractorSelected == null },
            handler: async () => {
              await this.synchroWithClient();
            }
          }
        ]
      },
      {
        group: "excel",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("grid.excelExport"),
            icon: "digi-xls-file",
            handler: async () => {
              await this.priceOfferLinesGrid.exportToExcel();
            },
            disabled: () => {
              return this.priceOfferLinesGrid == null;
            }
          }
        ]
      }
    ]
  }

  private async synchroWithClient() {
    let ids = await this.progressStatementLineApiService.synchroWithSubContractor(this.projectId, this.subContractorSelected as number);
    this.priceOfferLinesGrid.refreshVisibleNodes(ids);
    await this.getMeteringTotalPrices();
  }

  public async subContractorSelectedChanged(newVal, oldVal) {
    this.showGrid = false;
    if (newVal != null) {
      this.progressStatementLineApiService.subContractorId = newVal;
      let uri = this.router.generate("progress-statement-subcontractor", { "subContractorId": newVal });
      this.router.navigate(uri, { "replace": true });
      await this.getMeteringTotalPrices();
    }
    else {
      this.progressStatementLineApiService.subContractorId = null;
    }
  }

  private async createProgressStatement() {
    let msg = this.i18n.tr('progressstatement.confirmationQuestion');

    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.ok", { ns: "common" }),
      title: this.i18n.tr("general.ok", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          let progressStatementId = await this.progressStatementLineApiService.getLastProgressStatementId(this.projectId, this.subContractorSelected as number);
          this.router.navigateToRoute('progress-statement-subcontractor-detail', { projectId: this.projectId, progressStatementId: progressStatementId, progressStatementVersionId: -100, subContractorId: this.subContractorSelected });
        }
      })
  }

  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.realization") + "_" + this.i18n.tr("realizationindex.subContractorProgressStatementInProgress") + "_" + (await this.thirdPartyService.firstEntity(new Predicate('id', FilterQueryOp.Equals, this.subContractorSelected), [], { onlyActive: false })).fullName;
  }

}
