import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, FileFlow, EnumerationTypeService, Language, Various } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine, computedFrom, observable, viewResources } from 'aurelia-framework';
import * as Constants from '../constants';

@autoinject
export class ContactPersonDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ContactPerson> {
  documentTitle: string = "";
  ressourceName: string = Constants.EntityTypeNames.ContactPerson;

  private thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private companyFunctionService: EnumerationTypeService;
  private contactPersonRoleEnumService: EnumerationTypeService;
  @observable
  private isCreatingNewContact: boolean = true;
  private isEditingContact: boolean = true;

  private newThirdParty: Merlin.Web.Model.ThirdParty;
  private currentThirdParty: Merlin.Web.Model.ThirdParty;

  companyId: number;
  personId: number;

  isLinkedCompany: boolean = false;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    this.thirdPartiesService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.thirdPartiesService.gridDataSource.expands = ['addresses'];
    this.companyFunctionService = new EnumerationTypeService(Constants.EnumerationTypes.CompanyFunction);
    this.contactPersonRoleEnumService = new EnumerationTypeService(Constants.EnumerationTypes.ContactPersonRole);
    super.initialize(new ServiceBase<Merlin.Web.Model.ContactPerson>(Constants.EntityTypeNames.ContactPerson));
  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    if (params.callback) params.callback(this);
    this.isLinkedCompany = this.personId != null && this.companyId == null;
    this.currentThirdParty = await this.thirdPartiesService.getEntityById(this.personId ?? this.companyId);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (this.isCreatingNewContact) {
        await this.createNewthirdParty()
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'companyFunction', 'contactPersonRoles.role',
        'person.civility', 'person.companyType', 'person.spokenLanguage', 'person.thirdPartyTypes.thirdPartyTypeEnum',
        'person.thirdPartyTags.thirdPartyTagEnum', 'person.addresses');
      this.controller.addObject(this.entity);
      this.newThirdParty = this.entity.person;
      this.isCreatingNewContact = false;
      this.isEditingContact = false;
    }

    if (this.isLinkedCompany) {
      this.thirdPartiesService.gridDataSource.queryParameters = { onlyCompany: true }
    }
    else {
      this.thirdPartiesService.gridDataSource.queryParameters = { onlyPerson: true, emailNotNull: true }
    }
  }

  async isCreatingNewContactChanged(newVal, oldVal) {
    if (newVal && this.newThirdParty == null && this.thirdPartiesService != null) {
      await this.createNewthirdParty()
    }
  }

  public async createNewthirdParty() {
    this.newThirdParty = await this.thirdPartiesService.createEntity({ spokenLanguageId: null, companyTypeId: null, civilityId: null, underVat: true });
    this.newThirdParty.isCompany = this.isLinkedCompany;
    this.newThirdParty.underVat = this.newThirdParty.isCompany;
    this.newThirdParty.active = true;

    if (!this.isLinkedCompany) {
      let thirdPartyTypeService = new ServiceBase<Merlin.Web.Model.ThirdPartyType>(Constants.EntityTypeNames.ThirdPartyType);
      let thirdPartyTypeEnumService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyType);
      let typeContact: Merlin.Web.Model.ThirdPartyType = await thirdPartyTypeService.createEntity();
      typeContact.thirdPartyTypeEnum = await thirdPartyTypeEnumService.getEntityById(Constants.ThirdPartyType.Contact);
      typeContact.thirdParty = this.newThirdParty;
    }
  }

  public addCallbackAddress(address: Merlin.Web.Model.Address) {
    address.thirdPartyId = this.newThirdParty.id;
    address.thirdParty = this.newThirdParty;
  }

  async saveCurrentEntity(silentSave?: boolean): Promise<Merlin.Web.Model.ContactPerson> {
    if (this.isCreationMode) {
      if (!this.isCreatingNewContact) {
        this.newThirdParty.thirdPartyTypes?.forEach(type => {
          type.entityAspect.setDetached();
        });
        this.newThirdParty.entityAspect.setDetached();
      }
      else {
        if (this.isLinkedCompany) {
          this.entity.company = this.newThirdParty;
        }
        else {
          this.entity.person = this.newThirdParty;
        }
      }

      if (!this.isLinkedCompany) {
        this.entity.companyId = this.companyId;
      }
      else {
        this.entity.personId = this.personId;
      }

    }
    await super.save(silentSave);
    return this.entity;
  }
}
