import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { PriceOfferLineCategory, RefToDisplay } from './../../../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { Merlin } from 'generated';
import { autoinject, BindingEngine, Container, Disposable, getChangeRecords, observable, TaskQueue } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { CustomLogger, EnumerationType, EnumerationTypeService, FieldType, ServiceBase, GlobalLoaderService, UIInternal, IMenuItem, IMenuItems, Box, ActionDialogBoxInputParameters, DialogBoxViewModel, KeyboardShortcut } from "digiwall-lib";
import * as Constants from '../../../constants';
import * as toastr from 'toastr';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { BuyingComparisonService, IComparisonService, OfferComparisonService, ThirdPartyComparison } from 'services/offer-comparison-api-service';
import { I18N } from 'aurelia-i18n';
import { ColDef, ColGroupDef, RowNode, ServerSideTransaction, ValueSetterParams } from 'ag-grid-community';
import CommentRenderer from 'resources/renderer/comment-renderer';
import { AgCellCommentService } from 'resources/elements/ag-cell-comment/ag-cell-comment-service';
import { ComparaisonPriceOfferLineCommentApiService } from '../../../services/comparison-price-offer-line-comment-api-service';
import { DataFormat } from 'select2';
import { IdsToCompareSingleton } from 'resources/utilities/IdsToCompareSingleton';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { ContractSupplierOfferApiService } from 'services/contract-supplier-offer-api-service';
import { ThirdPartyContractDetail } from 'projects/buying/third-party-contract/third-party-contract-detail';
import { SupplierOfferMenuItem } from 'supplier-offers/supplier-offer-menu-item';
import { SupplierOfferApiService } from 'services/supplier-offer-api-service';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';

export const COMPARAISON_REFRESH_HEADER = "COMPARAISON_REFRESH_HEADER";

@autoinject
export class OfferComparison {

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: true },
      { colId: "supplierId", visibility: true },
    ];

  public getGridMenuItems = (params) => [
    {
      group: "1",
      hiddenLabel: true,
      items: [
        {
          label: this.i18n.tr("metering.unselectPrice"),
          icon: "digi-forbid-line",
          disabled: () => {
            // if (params.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data) {
            return params.node.data?.supplierId == null && params.node.data?.priceOrigin != Constants.PriceOrigin.Estim;
            // }
            // return !params.data.isSelected;
          },
          handler: async () => {
            await this.unSelectPrice(params)
          }
        },
      ]
    },
    ...this.supplierOfferMenuItem.getSupplierOfferMenuItem(params, this.grid, false, this.supplierOfferVersionApiService, parseInt(this.offerComparisonService.suppliers.filter(x => x.key != 0)[0].key), this.getMeteringTotalPrices, this)
  ];

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }
  public screenExpand: boolean = false;

  public requestSupplierOfferService: ServiceBase<Merlin.Web.Model.RequestSupplierOffer>;
  public supplierOfferService: ServiceBase<Merlin.Web.Model.SupplierOffer>;
  public activityService: EnumerationTypeService;

  @observable
  public requestSupplierOfferSelected: DataFormat | number = null;

  public supplierSelecteds: Array<number>;
  public allPossibleSupplier: Array<ThirdPartyComparison>;

  public activitySelecteds: Array<number>;
  public allPossibleActivity: Array<EnumerationType>;

  public workQuotationVersionId: number;
  public projectId: number;

  public displayGrid: boolean = false

  @observable
  public grid: PriceOfferLinesGrid;

  private fromParams: boolean = false;

  private disposables: Array<Disposable> = [];

  private offerComparisonService: IComparisonService;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number;
  private showPU: boolean = false;
  private showQTE: boolean = false;
  private showComment: boolean = false;
  public displayEstim: boolean = false;
  public getHeaderMenuItems = [];

  public disabledDisplayButton = false;
  public hasBeenLoadedOnce = false;
  private buyingContractService: ServiceBase<Merlin.Web.Model.BuyingContract>
  private projectService: ServiceBase<Merlin.Web.Model.Project>

  constructor(public logger: CustomLogger, public router: Router, private i18n: I18N, private taskQueue: TaskQueue, private bindingEngine: BindingEngine, private globalLoaderService: GlobalLoaderService, private idsToCompareSingleton: IdsToCompareSingleton, private projectApiService: ProjectApiService, private contractSupplierOfferVersionApiService: ContractSupplierOfferApiService, private supplierOfferMenuItem: SupplierOfferMenuItem, private supplierOfferVersionApiService: SupplierOfferApiService, private box: Box, private workQuotationVersionApiService: WorkQuotationVersionApiService) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.activitySelecteds).subscribe(changeRecords => {
        if (this.activitySelecteds.length > 0) {
          this.supplierSelecteds.splice(0);
        }
      })
    );
    UIInternal.subscribe(COMPARAISON_REFRESH_HEADER, () => {
      if (this.grid?.gridOptions.api.getDisplayedRowCount() != 0) {
        this.grid?.gridOptions.api.refreshHeader();
      }
    });

  }

  private async getThirdParties(): Promise<Array<ThirdPartyComparison>> {
    return await this.offerComparisonService.getThirdParty((typeof this.requestSupplierOfferSelected === "object" ? parseInt(this.requestSupplierOfferSelected?.id.toString()) : parseInt(this.requestSupplierOfferSelected.toString())))
  }

  public async activate(params: any) {
    this.getComparisonSetting();
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.projectId = params.projectId;
    if (this.workQuotationVersionId != null) {
      this.offerComparisonService = Container.instance.get(OfferComparisonService);
      this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer);
      this.supplierOfferVersionApiService.type = DocumentTypeDiscriminator.WorkQuotationVersion;
    }
    else if (this.projectId != null) {
      this.offerComparisonService = Container.instance.get(BuyingComparisonService);
      this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer);
      this.buyingContractService = new ServiceBase<Merlin.Web.Model.BuyingContract>(Constants.EntityTypeNames.BuyingContract);
      this.supplierOfferVersionApiService.type = DocumentTypeDiscriminator.Buying;
      this.supplierOfferService = new ServiceBase<Merlin.Web.Model.SupplierOffer>(Constants.EntityTypeNames.SupplierOffer);
    }

    this.requestSupplierOfferService.gridDataSource.queryParameters = { workQuotationVersionId: this.workQuotationVersionId, hasOneOrMoreOffer: true, projectId: this.projectId };

    this.supplierSelecteds = new Array();
    if (this.allPossibleSupplier == null) {
      this.allPossibleSupplier = new Array<ThirdPartyComparison>();
    }


    this.allPossibleActivity = await this.activityService.getEntities(null, null, { category: Constants.EnumerationTypes.ActivityCode, workQuotationVersionId: this.workQuotationVersionId, projectId: this.projectId })
    this.activitySelecteds = new Array();

    if (params.requestOfferId != null) {
      let request = await this.requestSupplierOfferService.firstEntity(new Predicate("id", FilterQueryOp.Equals, params.requestOfferId));
      this.fromParams = true;
      this.requestSupplierOfferSelected = { text: request.requestOfferName, id: params.requestOfferId };
    } else {
      let supplier = await this.getThirdParties();
      this.allPossibleSupplier.push(...supplier);
    }

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;


    this.getHeaderMenuItems = [{
      group: "excel",
      hiddenLabel: true,
      items: [
        {
          label: this.i18n.tr("grid.excelExport"),
          icon: "digi-xls-file",
          handler: async () => {
            await this.grid.exportToExcel();
          },
          disabled: () => {
            return this.grid == null;
          }
        }
      ]
    }];
  }

  public async requestSupplierOfferSelectedChanged(newVal, oldVal) {
    if (newVal != oldVal) {
      if (this.allPossibleSupplier == null) {
        this.allPossibleSupplier = new Array<ThirdPartyComparison>();
      }
      if (this.supplierSelecteds == null) {
        this.supplierSelecteds = new Array();
      }
      this.supplierSelecteds.splice(0);
      this.allPossibleSupplier.splice(0);

      if (newVal != oldVal && newVal != null) {
        if (typeof newVal === "object") {
          newVal = parseInt(newVal.id);
        }

        this.allPossibleSupplier.push(...await this.getThirdParties());
        if (this.fromParams) {
          /*this.allPossibleSupplier.forEach(supplier => {
            this.supplierSelecteds.push(supplier.id);
          });*/
          let selectedIds = this.idsToCompareSingleton.getIds();
          this.allPossibleSupplier
            .filter(supplier => selectedIds.find(x => x == supplier.id))
            .forEach(supplier => {
              this.supplierSelecteds.push(supplier.id);
            });
        }
      } else {
        this.allPossibleSupplier.push(...await this.getThirdParties());
      }

      if (this.fromParams && this.supplierSelecteds.length >= 2) {
        this.displayComparison()
      }
    }
  }

  private firstDisplay = true;

  public displayComparison() {
    if (this.activitySelecteds.length == 0 && this.supplierSelecteds.length < 1) {
      toastr.error(this.i18n.tr("offercomparison.supplierError"));
      return false;
    }
    this.disabledDisplayButton = true;
    if (this.requestSupplierOfferSelected != null) {
      this.columnVisible.find(x => x.colId === "originalProposedQuantity").visibility = true;
    }
    else {
      this.columnVisible.find(x => x.colId === "originalProposedQuantity").visibility = false;
    }

    this.displayGrid = true;

    if (!this.firstDisplay) {
      this.removeSuppliersColumns();
      this.grid.gridOptions.api.setColumnDefs(this.grid.getDataGridColumns());
    }

    this.taskQueue.queueTask(async () => {
      if (this.requestSupplierOfferSelected != null) {
        if (typeof this.requestSupplierOfferSelected === "object") {
          //Id can be either string or number, making sure that he is a number
          this.offerComparisonService.requestSupplierOfferId = parseInt(this.requestSupplierOfferSelected.id.toString());
        }
        else {
          this.offerComparisonService.requestSupplierOfferId = this.requestSupplierOfferSelected as unknown as number;
        }
      } else {
        this.offerComparisonService.requestSupplierOfferId = null;
      }

      if (this.supplierSelecteds.length > 0) {
        this.offerComparisonService.supplierIds = this.supplierSelecteds;
      }
      if (this.activitySelecteds.length > 0) {
        this.offerComparisonService.activitiesIds = this.activitySelecteds
      }

      if (this.grid.listTreeData != null) {
        this.grid.listTreeData.onDataLoaded = async () => {
          this.createColumns();
          this.getMeteringTotalPrices();
        };
      }


      if (!this.firstDisplay) {
        this.grid.refreshServerSideRows(null, true);
        await this.getMeteringTotalPrices();
      }

      this.firstDisplay = false;
    });
  }

  getComparisonSetting() {
    let localStorage = window.localStorage.getItem('comparisonSetting');
    let json = JSON.parse(localStorage);
    if (json != null) {
      this.showPU = json.showPU;
      this.showQTE = json.showQTE;
      this.showComment = json.showComment;
      this.displayEstim = json.displayEstim;
    }
  }

  saveComparisonSetting() {
    let comparisonSetting = {
      showPU: this.showPU,
      showQTE: this.showQTE,
      showComment: this.showComment,
      displayEstim: this.displayEstim
    };
    window.localStorage.setItem('comparisonSetting', JSON.stringify(comparisonSetting));
  }
  async getMeteringTotalPrices() {
    let requestSupplierOfferSelectedId: number;
    if ((this?.requestSupplierOfferSelected as DataFormat)?.id) {
      requestSupplierOfferSelectedId = parseInt((this?.requestSupplierOfferSelected as DataFormat).id?.toString());
    } else {
      requestSupplierOfferSelectedId = (this?.requestSupplierOfferSelected as number);
    }
    let data = await this?.offerComparisonService.getMeteringTotalPricesData(this?.getVersionOrProjectId(), this?.activitySelecteds, this?.supplierSelecteds, requestSupplierOfferSelectedId);
    this?.grid.gridOptions.api!.setPinnedBottomRowData([data]);
  }

  private removeSuppliersColumns(): void {
    let colDefs: Array<ColDef<any>> = this.grid.gridOptions.api.getColumnDefs();
    for (let supName of this.offerComparisonService.suppliers) {
      let cdi = colDefs.findIndex(cd => cd.colId == supName);
      if (cdi != -1) {
        colDefs.splice(cdi, 1);
      }
    }
    let estimColumn = colDefs.findIndex(cd => cd.headerName == this.i18n.tr("metering.estimation")); //Remove estim column
    if (estimColumn != -1) {
      colDefs.splice(estimColumn, 1);
    }
    this.grid.gridOptions.api.setColumnDefs(colDefs);
  }

  private createColumns(): void {
    this.removeSuppliersColumns();

    let colDefs: Array<ColDef<any> | ColGroupDef<any>> = this.grid.gridOptions.api.getColumnDefs();

    if (this.displayEstim) {
      this.getEstimColumn(colDefs)
    }

    this.offerComparisonService.suppliers.sort((a, b) => {
      return a.key == 0 ? 1 : b.key == 0 ? -1 : 0;
    })
      .forEach(supName => {
        if (colDefs.some(cd => cd.headerName == supName.key)) return;
        if (/*(this.showPU || this.showQTE || this.showComment) && */(supName.key != "0" || supName.key != 0)) {
          this.createColumnWithGroup(colDefs, supName);
        } else {
          this.createColumnWithoutGroup(colDefs, supName);
        }
      }
      );
    this.grid.gridOptions.api.setColumnDefs(colDefs);
    if (this.disabledDisplayButton) {
      this.disabledDisplayButton = false
    }
    setTimeout(() => {
      this.resizeColumnToFitContent();
    }, 1);
    this.grid.gridOptions.onVirtualColumnsChanged = (event) => {
      this.resizeColumnToFitContent();
    }
  }


  public afterSetAgGridView() {
    this.resizeColumnToFitContent();
  }

  private resizeColumnToFitContent() {
    var columnToResizes: string[] = ["estimatedBuyingUnitPrice", "estimatedQuantity", "estimatedTotalBuyingPrice"]
    this.offerComparisonService.suppliers
      .forEach(supName => {
        columnToResizes.push(supName.key);
        columnToResizes.push(supName.key + "_1");
        columnToResizes.push(supName.key + "_2");
        columnToResizes.push(supName.key + "_3");
      });

    columnToResizes.forEach(columnToResize => {
      let column = this.grid.gridOptions.columnApi.getColumn(columnToResize);
      if (column != null && column.getActualWidth() == 200) {
        this.grid.gridOptions.columnApi.autoSizeColumn(columnToResize, false);
      }
    });
  }

  private getEstimColumn(colDefs: (ColDef<any> | ColGroupDef<any>)[]) {
    let children: (ColDef<any> | ColGroupDef<any>)[] = [];
    if (this.showPU) children.push(this.setComparisonParamsToEstimColumn(this.grid.getEstimatedBuyingUnitPriceColDef()));
    if (this.showQTE) children.push(this.getEstimatedQuantityColDef());

    children.push(this.getEstimatedTotalColumn());

    if (this.showComment) children.push(this.getEstimatedCommentColDef());
    if (this.workQuotationVersionId != null)
      children.push(this.getSelectionStateColumn("estim", true))


    colDefs.push({
      headerName: this.i18n.tr("metering.estimation"),
      headerGroupComponent: "merlinHtmlHeaderRenderer",
      headerGroupComponentParams: {
        getHtml: (currentThis) => {
          return this.getHeaderHtml(currentThis, true);
        },
        checkedChanged: async (event, params, removeLink: boolean) => {
          await this.headerSelectedChanged(params, removeLink, true);
        },
        display: this.i18n.tr("metering.estimation")
      },
      children: children
    });
  }

  private setComparisonParamsToEstimColumn(colDef: ColDef<any>) {
    colDef.editable = (params) => this.canEditCell(params.data, params.colDef.field);
    return colDef;
  }

  private getEstimatedCommentColDef() {
    var commentColumn = this.setComparisonParamsToEstimColumn(this.grid.getEstimatedCommentColDef());
    commentColumn.headerValueGetter = () => this.i18n.tr("offercomparison.comment");
    return commentColumn;
  }

  private getEstimatedQuantityColDef() {
    var quantityColumn = this.setComparisonParamsToEstimColumn(this.grid.getEstimatedQuantityColDef());
    quantityColumn.cellClass = (params) => this.getCellCssClass(params);
    quantityColumn.cellRenderer = 'customHtmlRendererEditor'
    quantityColumn.cellRendererSelector = (params) => {
      if (params.colDef.field != "0") {
        return {
          "component": CommentRenderer,
          "params": {
            ...params,
            "baseRenderer": params.colDef.cellRenderer,
            "components": this.grid.gridOptions.components
          }
        };
      }
    }
    quantityColumn.cellRendererParams = {
      getHtml: (currentThis) => {
        let data = currentThis.params?.data;
        return this.getQuantityColumnHtml(data.proposedQuantity, data.estimatedQuantity);
      },
      canComment: false,
      isComparisonColumn: true
    }
    return quantityColumn;
  }

  private getEstimatedTotalColumn() {
    var totalColumn = this.setComparisonParamsToEstimColumn(this.grid.getEstimatedTotalBuyingPriceColDef());
    totalColumn.cellClass = (params) => this.getCellCssClass(params);
    totalColumn.valueGetter = (params) => {
      let data = params?.data;
      if (data.estimatedTotalBuyingPrice != null)
        return (!isNaN(data?.estimatedTotalBuyingPrice) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data?.estimatedTotalBuyingPrice) : data?.estimatedTotalBuyingPrice)
    }
    return totalColumn;
  }

  private getCellCssClass(params, displayHasMinValue: boolean = false) {
    if (this == null) return ""
    let cssClass = "test";

    let data = this.getCurrentSupplierData(params);
    let hasContract = data?.hasContract ?? false;
    if (hasContract) cssClass += " has-contract";

    if (params.colDef.field == "0") {
      if (params.data.priceOfferLineCategoryId == PriceOfferLineCategory.Chapter) {
        return cssClass + " supplier-cell-total-chapiter";
      }
      return cssClass + " supplier-cell-total-data";
    }
    if (data?.isMinValue && displayHasMinValue) {
      cssClass = cssClass + " supplier-min-value"
    }
    if (params.column.colDef.field.split("-")[1] == "supplierTotal" || params.column.colDef.field == "estimatedTotalBuyingPrice") {
      cssClass = cssClass + " supplier-total"
    }
    return cssClass + " metering-cell-description";
  }

  private createColumnWithoutGroup(colDefs: (ColDef<any> | ColGroupDef<any>)[], supName: any) {
    let columnTotalPrice = this.getTotalPriceColumn(supName);
    columnTotalPrice.headerName = supName.value;
    columnTotalPrice.colId = supName.key;
    columnTotalPrice.headerComponent = "merlinHtmlHeaderRenderer";
    columnTotalPrice.headerComponentParams = {
      getHtml: (currentThis) => {
        return this.getHeaderHtml(currentThis);
      },
      checkedChanged: async (event, params, removeLink: boolean) => {
        await this.headerSelectedChanged(params, removeLink);
      },
      display: supName.value,
      id: supName.key
    };
    columnTotalPrice.headerClass = (params) => {
      if ((params.colDef as any).colId == "0") {
        return "supplier-cell-total";
      }
      return '';
    }
    columnTotalPrice.field = supName.key;

    colDefs.push(columnTotalPrice);
  }

  private createColumnWithGroup(colDefs: (ColDef<any> | ColGroupDef<any>)[], supName: any) {
    let children: (ColDef<any> | ColGroupDef<any>)[] = [];
    if (this.showPU) children.push(this.getUnitPriceColumn(supName));
    if (this.showQTE) children.push(this.getQuantityColumn(supName));
    children.push(this.getTotalPriceColumn(supName));
    if (this.showComment) children.push(this.getCommentColumn(supName));
    children.push(this.getSelectionStateColumn(supName.key))

    colDefs.push({
      headerName: supName.value,
      headerGroupComponent: "merlinHtmlHeaderRenderer",
      headerGroupComponentParams: {
        getHtml: (currentThis) => {
          return this.getHeaderHtml(currentThis);
        },
        checkedChanged: async (event, params, removeLink: boolean) => {
          await this.headerSelectedChanged(params, removeLink);
        },
        display: supName.value,
        id: supName.key
      },
      children: children
    });
  }

  private getTotalPriceColumn(supName): ColDef {
    return {
      colId: supName.key,
      headerName: this.i18n.tr('offercomparison.totalPrice'),
      field: supName.key + '-supplierTotal',
      type: FieldType.Number,
      suppressMenu: true,
      sortable: false,
      suppressMovable: false,
      cellClass: (params) => {
        return this.getCellCssClass(params, true);
      },
      editable: (params) => this.canEditCell(params.data, "supplierTotal"),
      showRowGroup: this.i18n.tr("offercomparison.suppliers"),
      valueGetter: (params) => {
        let data = this.getCurrentSupplierData(params);
        if (data.totalPrice != null)
          return (!isNaN(data?.totalPrice) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data?.totalPrice) : data?.totalPrice)
      },
    }
  }
  private getUnitPriceColumn(supName): ColDef {
    return {
      colId: supName.key,
      headerName: this.i18n.tr('offercomparison.unitPrice'),
      field: supName.key + '-supplierPrice',
      type: FieldType.Number,
      cellClass: (params) => {
        return this.getCellCssClass(params);
      },
      editable: (params) => this.canEditCell(params.data, "unitPrice"),
      valueGetter: (params) => {
        let data = this.getCurrentSupplierData(params);
        if (data.unitPrice != null)
          return (!isNaN(data.unitPrice) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.unitPrice) : data.unitPrice);
      }
    }
  }
  private getCommentColumn(supName): ColDef {
    return {
      colId: supName.key,
      headerName: this.i18n.tr('offercomparison.comment'),
      field: supName.key + '-supplierComment',
      type: FieldType.String,
      cellClass: (params) => {
        return this.getCellCssClass(params);
      },
      editable: (params) => this.canEditCell(params.data, "supplierComment"),
      valueGetter: (params) => {
        let data = this.getCurrentSupplierData(params);
        return data?.additionalCommentMultiline;
      },
    }
  }
  private getQuantityColumn(supName): ColDef {
    return {
      colId: supName.key,
      headerName: this.i18n.tr('offercomparison.quantity'),
      field: supName.key + '-supplierQuantity',
      cellRenderer: 'customHtmlRendererEditor',
      type: FieldType.Number,
      cellClass: (params) => {
        return this.getCellCssClass(params);
      },
      cellRendererParams: {
        getHtml: (currentThis) => {
          UIInternal.broadcast(COMPARAISON_REFRESH_HEADER);
          let data = this.getCurrentSupplierData(currentThis.params)
          if (data == null) return ''
          return this.getQuantityColumnHtml(data.quantity, data.modifiedQuantity);
        },
        supplierField: supName,
        canComment: false,
        isComparisonColumn: true
      },
      editable: (params) => this.canEditCell(params.data, "modifiedQuantity"),
      valueGetter: (params) => {
        let data = this.getCurrentSupplierData(params);
        return data?.modifiedQuantity;
      },
    }
  }
  private getQuantityColumnHtml(quantity: number, modifiedQuantity: number) {
    let result = '<div class="supplier-quantity">'
    if (modifiedQuantity) {
      result += "<div>"
      if (modifiedQuantity != null && quantity != null && modifiedQuantity != quantity) {
        if (modifiedQuantity > quantity) {
          result += '<ui-icon icon="digi-arrow-right-up-line"></ui-icon>'
        }
        else {
          result += '<ui-icon icon="digi-arrow-right-down-line"></ui-icon>'
        }
      }
      result += `</div><div>${(!isNaN(modifiedQuantity) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(modifiedQuantity) : modifiedQuantity)}</div>`
    }
    result += "</div>"
    return result;
  }
  private getSelectionStateColumn(colId, isEstimationColumn: boolean = false): ColDef {
    let width = 60;
    return {
      colId: colId,
      headerName: '',
      field: colId + '-selected',
      type: FieldType.Number,
      suppressMenu: true,
      sortable: false,
      suppressMovable: true,
      width: width,
      maxWidth: width,
      minWidth: width,
      cellClass: (params) => {
        return this.getCellCssClass(params);
      },
      cellRenderer: 'customHtmlRendererEditor',
      cellRendererParams: {
        getHtml: (currentThis) => {
          if (isEstimationColumn) {
            return this.getSelectionStateForEstimation(currentThis);
          }
          else {
            return this.getSelectionStateForSupplier(currentThis);
          }
        },
        canComment: false,
        checkedChanged: async (event, params, removeLink: boolean) => {
          this.grid.gridOptions.api.showLoadingOverlay();
          await this.selectedDataChanged(params, removeLink, isEstimationColumn);
          this.grid.gridOptions.api.hideOverlay();
        },
        isComparisonColumn: true
      },
      cellRendererSelector: (params) => {
        if (params.colDef.field != "0") {
          return {
            "component": CommentRenderer,
            "params": {
              ...params,
              "baseRenderer": params.colDef.cellRenderer,
              "components": this.grid.gridOptions.components
            }
          };
        }
      },
      showRowGroup: this.i18n.tr("offercomparison.suppliers"),
      valueGetter: (params) => {
        let data = this.getCurrentSupplierData(params);
        return data?.totalPrice;
      },

    }
  }

  public canEditCell(line: any, colField: string): boolean {
    if (line == null || colField == null) return false;
    switch (colField) {
      case "quantityTypeId":
      case "unitId":
      case "proposedQuantity":
      case "unitQuantityFormulaDetailItemComposed":
      case "unitQuantityDetailItemComposed":
      case "marginCoefficient":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
      case "dataLineActivities":
      case "dataLineDeliveries":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          || line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;
      case "buyingClientValidationStatusId":
        return line.buyingClientValidationDeadlineDate != null;
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "buyingClientValidationStatusId":
      case "buyingClientValidationDeadlineDate":
      case "buyingClientValidationDate":
      case "deliveryDeadlineDate":
        return true;
      case "unitPrice":
      case "supplierTotal":
      case "estimatedBuyingUnitPrice":
      case "estimatedTotalBuyingPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && !line.hasChildrenSubItem;
      case "modifiedQuantity":
      case "estimatedQuantity":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && !line.hasChildrenDetailed && this.supplierOfferVersionApiService.type != DocumentTypeDiscriminator.Buying;
      case "supplierComment":
      case "estimatedComment":
        return true;
      default:
        return false;
    }

  }

  public cellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "supplierComment":
        return;// Don't getMeteringTotalPrices for these properties
    }

    this.getMeteringTotalPrices();
  }

  private getHeaderHtml(currentThis: any, isEstimationColumn = false) {
    let name = currentThis.params.display;
    let hasContract: boolean = false;
    let nodeofColumn: Array<number> = [];
    currentThis.params.api.forEachNode(x => {
      if (!hasContract && !isEstimationColumn) {
        hasContract = x?.data?.supplierPrices[currentThis.params.id]?.hasContract ?? false;
      }
      if (x?.data?.lineLevel == 1) {
        let selectionState = isEstimationColumn ? x?.data?.estimatedSelectionState : x?.data?.supplierPrices[currentThis.params.id].selectionState;
        if (selectionState != null) {
          nodeofColumn.push(selectionState);
        }
      }
    });

    let none = nodeofColumn.every(x => x == Constants.NodeSelectionState.None);
    let all = nodeofColumn.every(x => x == Constants.NodeSelectionState.All);

    let result = `<div class="ag-header supplier-header-cell-value">
                  <div>${name}</div>`;
    if (!hasContract && currentThis.params.id != 0 && ((isEstimationColumn && this.workQuotationVersionId != null) || !isEstimationColumn)) {
      if (none) {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-checkbox-blank-line" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                        </ui-button>
                      </div>`;
      } else if (all) {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-check-full" type="tool" click.delegate="params.checkedChanged(null, params, true)">
                        </ui-button>
                      </div>`;
      } else {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-checkbox-square" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                        </ui-button>
                      </div>`;
      }
    }
    result += '</div>';
    return result;
  }

  private getSelectionStateForEstimation(currentThis) {
    let data = currentThis.params?.data
    if (data == null) return
    if (data.estimatedTotalBuyingPrice != null && data.id != 0) {
      return '<div class="supplier-cell">' + this.getSelectionStateHtml(currentThis, data.estimatedSelectionState, data.priceOrigin == Constants.PriceOrigin.Estim, data.id) + '</div>';
    }
    return '';
  }
  private getSelectionStateForSupplier(currentThis) {
    let data = this.getCurrentSupplierData(currentThis.params)
    if (data == null) {
      return ''
    }
    let hasContract: boolean = data?.hasContract ?? false;
    if (data.totalPrice != null && currentThis.params.colDef.field != "0" && currentThis.params.data.id != 0 && !hasContract) {
      return '<div class="supplier-cell">' + this.getSelectionStateHtml(currentThis, data.selectionState, data.selected, data.supplierOfferLineId) + '</div>';
    }
    return ''
  }

  private getSelectionStateHtml(currentThis: any, currentSelectionState: number, selected: boolean, model: any) {
    let result = '';
    if (currentThis.params?.data.priceOfferLineCategoryId == PriceOfferLineCategory.Chapter || (currentThis.params?.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data && (currentThis.params?.data.hasChildrenDetailed || currentThis.params?.data.hasChildrenSubItem))) {
      switch (currentSelectionState) {
        case Constants.NodeSelectionState.All: {
          result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-check-full" type="tool" click.delegate="params.checkedChanged(null, params, true)">
                          </ui-button>
                            </span>`;
          break;
        }
        case Constants.NodeSelectionState.Partial: {
          result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-checkbox-square" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                          </ui-button>
                            </span>`;
          break;
        }
        case Constants.NodeSelectionState.None:
        default: {
          result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-checkbox-blank-line" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                          </ui-button>
                            </span>`;
          break;
        }
      }
    } else if (currentThis.params.data.disabledComparisonLine) {
      result += `<span class="supplier-cell-value-radio-disabled"><ui-icon icon="digi-round"></ui-icon></span>`;
    }
    else {
      result += `<div class="supplier-cell-value-radio">
                      <ui-radio on-change.call="params.checkedChanged($event, params, ${selected})" checked='${selected ? model : null}' model="${model}" name="${currentThis.params?.data.id}"></ui-radio>
                    </div></div>`;
    }
    return result;
  }

  private getDBId(id): number {
    let lineId: string = id;

    if (id.toString().includes("_")) {
      lineId = id.toString().split("_")[0].split('-')[1];
    }
    return parseInt(lineId);
  }

  private getVersionOrProjectId() {
    return this.workQuotationVersionId != null ? this.workQuotationVersionId : this.projectId
  }

  selectDataInProcess: boolean = false;

  private async selectedDataChanged(params, removeLink: boolean, isEstimationColumn: boolean) {
    if (!this.selectDataInProcess) {
      this.selectDataInProcess = true;
      if (!removeLink) {
        let ids: Array<number>;
        let keepModifiedQuantity = true;
        this.globalLoaderService.allow(true, 1);
        if (isEstimationColumn) {
          let currentLineHasDifferentEstimQty = params.data.estimatedQuantity != null && params.data.proposedQuantity != null && params.data.estimatedQuantity != params.data.proposedQuantity;
          let childrenHasDifferentEstimQty = false;
          this.getDescendants(params.data).forEach(x => {
            if (childrenHasDifferentEstimQty) return;
            childrenHasDifferentEstimQty = x.estimatedQuantity != null && x.proposedQuantity != null && x.estimatedQuantity != x.proposedQuantity;
          });
          if (currentLineHasDifferentEstimQty || childrenHasDifferentEstimQty) {
            keepModifiedQuantity = await this.questionKeepModifiedQuantity(this.i18n.tr('offercomparison.questionKeepEstimatedQuantity'));
          }
          ids = await this.offerComparisonService.acceptFromEstimatedPrice(this.getVersionOrProjectId(), params.data.id, keepModifiedQuantity);
        }
        else {
          let currentSupplierData = params?.data?.supplierPrices?.[params.colDef.colId.split('_')[0]];
          let currentSupplierDataHasDifferentQuantity = currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != currentSupplierData.quantity;
          let childrenHasDifferentQuantity = false;
          this.getDescendants(params.data).forEach(x => {
            if (childrenHasDifferentQuantity) return;
            let currentSupplierData = x.supplierPrices?.[params.colDef.colId.split('_')[0]];
            childrenHasDifferentQuantity = currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != currentSupplierData.quantity;
          });
          if (currentSupplierDataHasDifferentQuantity || childrenHasDifferentQuantity) {
            keepModifiedQuantity = await this.questionKeepModifiedQuantity(this.i18n.tr('offercomparison.questionKeepModifiedQuantity'));
          }
          ids = await this.offerComparisonService.createLinkBetweenPOLAndSOL(this.getVersionOrProjectId(), currentSupplierData.supplierOfferLineId, keepModifiedQuantity, null);
        }
        await this.refreshGrid(ids);

        this.getMeteringTotalPrices();
      }
      else {
        await this.unSelectPrice(params);
      }
      this.selectDataInProcess = false;
    }

  }
  private async questionKeepModifiedQuantity(question: string) {
    let keepModifiedQuantity;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(question, this.i18n.tr('menu.question'), [buttonNo, buttonYes])
      .whenClosed(async result => {
        keepModifiedQuantity = result.output;
      });

    return keepModifiedQuantity;
  }

  private getDescendants(line) {
    let descendant = [];
    let children = this.grid?.listTreeData?.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      descendant.push(child);
      descendant.push(...this.getDescendants(child));
    })
    return descendant;
  }

  getSiblingNodes(id: number) {
    let siblingNodes = new Array<RowNode<any>>();
    this.grid.gridOptions.api.forEachNode(x => {
      let nodeId = x.data.uniqueId;
      if (typeof (nodeId) == 'string') {
        let dbId = this.getDBId(nodeId);
        if (id == dbId) {
          siblingNodes.push(x);
        }
      }
    });
    return siblingNodes;
  }

  private removeNode(selected, transactions: ServerSideTransaction[]) {
    let parentRoute = selected.parent?.getRoute();
    let tr;
    if ((tr = transactions.find(t => t.route == parentRoute)) != null) {
      tr.remove.push(selected.data);
    } else {
      transactions.push({ route: parentRoute, remove: [selected.data] });
    }
  }

  private async headerSelectedChanged(params, removeLink: boolean, isEstimationColumn: boolean = false) {
    this.globalLoaderService.allow();
    let ids: Array<number>;
    let keepModifiedQuantity = true;

    if (!removeLink) {
      if (isEstimationColumn) {
        let childrenHasDifferentQuantity = false;
        this.grid.listTreeData.dataLines.filter(x => x.lineLevel == 1).forEach(line => {
          if (childrenHasDifferentQuantity) return;
          this.getDescendants(line).forEach(x => {
            if (childrenHasDifferentQuantity) return;
            childrenHasDifferentQuantity = x.estimatedQuantity != null && x.proposedQuantity != null && x.estimatedQuantity != x.proposedQuantity;
          });
        });

        if (childrenHasDifferentQuantity) {
          keepModifiedQuantity = await this.questionKeepModifiedQuantity(this.i18n.tr('offercomparison.questionKeepModifiedQuantity'));
        }

        ids = await this.offerComparisonService.acceptFromEstimatedPrice(this.getVersionOrProjectId(), null, keepModifiedQuantity);
      }
      else {
        let childrenHasDifferentQuantity = false;
        this.grid.listTreeData.dataLines.filter(x => x.lineLevel == 1).forEach(line => {
          if (childrenHasDifferentQuantity) return;
          this.getDescendants(line).forEach(x => {
            if (childrenHasDifferentQuantity) return;
            let currentSupplierData = x.supplierPrices?.[params.id];
            childrenHasDifferentQuantity = currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != null && currentSupplierData.modifiedQuantity != currentSupplierData.quantity;
          });
        });

        if (childrenHasDifferentQuantity) {
          keepModifiedQuantity = await this.questionKeepModifiedQuantity(this.i18n.tr('offercomparison.questionKeepModifiedQuantity'));
        }

        ids = await this.offerComparisonService.createLinkBetweenPOLAndSOL(this.getVersionOrProjectId(), null, keepModifiedQuantity, params.id);
      }
    }
    else {
      ids = await this.offerComparisonService.unSelectPriceOfSupplier(this.getVersionOrProjectId());
    }
    await this.refreshGrid(ids);
    this.grid.gridOptions.api.refreshHeader();
    this.getMeteringTotalPrices();
  }

  public canComment(coldDef: ColDef) {
    return false;
  }

  private gridChanged() {
    if (this.grid != null) {
      this.disposables.push(this.bindingEngine.propertyObserver(this.grid, "listTreeData").subscribe((newVal, oldVal) => {

        this.grid.listTreeData.onDataLoaded = async () => {
          if (!this.hasBeenLoadedOnce) {
            this.hasBeenLoadedOnce = true;
            this.createColumns();
            this.getMeteringTotalPrices();
          }
        };
      }))
    }

  }

  deactivate() {
    this.disposables.forEach(x => x.dispose());
  }

  async unSelectPrice(params) {
    let result = await this.offerComparisonService.unSelectPrice(this.getVersionOrProjectId(), params.node.data.id);
    if (result.length > 0) {

      await this.refreshGrid(result, true);
      this.getMeteringTotalPrices();
    }
    else {
      this.logger.LogError(this.i18n.tr("offercomparison.nothingToUnselectError"), null, null, true);
    }

  }

  private async refreshGrid(ids: Array<number>, unSelect: boolean = false) {
    let toRefresh = [];
    for (const id of ids) {
      let siblingNodes = this.getSiblingNodes(id);
      if (siblingNodes?.length > 1) {
        let currentRoute = siblingNodes[0].parent.getRoute();
        this.globalLoaderService.allow(true, 1);
        const newLines = await this.offerComparisonService.fetch(this.getVersionOrProjectId(), [id], false, this.grid.listTreeData.refToDisplay);
        const agGridTransaction: ServerSideTransaction = {};
        agGridTransaction.update = [];
        agGridTransaction.remove = [];
        agGridTransaction.route = currentRoute;


        siblingNodes.forEach(siblingNode => {
          const uniqueId = siblingNode.data.uniqueId;
          const updatedLine = newLines.find(x => x.uniqueId == uniqueId);
          if (updatedLine != null) {
            agGridTransaction.update.push(updatedLine);
          } else {
            agGridTransaction.remove.push(siblingNode.data);
          }

        });
        const transactionResult = this.grid.gridOptions.api.applyServerSideTransaction(agGridTransaction);
        this.grid.gridOptions.api.redrawRows({
          "rowNodes": transactionResult.update
        });
        this.grid.gridOptions.api.flashCells({ rowNodes: transactionResult.update });

      }
      else {
        if (unSelect) {
          let node = this.grid.listTreeData.gridOptions.api.getRowNode(id.toString());
          if (node != null) {
            node.data.selected = null;
          }
        }
        toRefresh.push(id);
      }
    }

    if (toRefresh?.length > 0) {
      this.grid.refreshVisibleNodes(toRefresh);
    }
  }

  internalCustomSettingMenuItems(): IMenuItems[] {
    return [
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {

            label: this.i18n.tr("offercomparison.showPU"),
            hidden: () => this.showPU,
            handler: () => {
              this.showPU = true;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },
          {
            label: this.i18n.tr("offercomparison.hidePU"),
            hidden: () => !this.showPU,
            handler: () => {
              this.showPU = false;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },

          {

            label: this.i18n.tr("offercomparison.showQTE"),
            hidden: () => this.showQTE,
            handler: () => {
              this.showQTE = true;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },
          {
            label: this.i18n.tr("offercomparison.hideQTE"),
            hidden: () => !this.showQTE,
            handler: () => {
              this.showQTE = false;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },

          {

            label: this.i18n.tr("offercomparison.showComment"),
            hidden: () => this.showComment,
            handler: () => {
              this.showComment = true;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },
          {
            label: this.i18n.tr("offercomparison.hideComment"),
            hidden: () => !this.showComment,
            handler: () => {
              this.showComment = false;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },

          {

            label: this.i18n.tr("offercomparison.displayEstim"),
            hidden: () => this.displayEstim,
            handler: () => {
              this.displayEstim = true;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          },
          {
            label: this.i18n.tr("offercomparison.hideEstim"),
            hidden: () => !this.displayEstim,
            handler: () => {
              this.displayEstim = false;
              this.saveComparisonSetting();
              this.displayComparison();
            }
          }
        ]
      },
    ];
  }

  private async createContracts() {
    let p1
    if (typeof this.requestSupplierOfferSelected === "number") {
      p1 = new Predicate("requestToSupplier.requestSupplierOfferId", FilterQueryOp.Equals, this.requestSupplierOfferSelected)
    }
    else {
      p1 = new Predicate("requestToSupplier.requestSupplierOfferId", FilterQueryOp.Equals, this.requestSupplierOfferSelected.id)
    }

    let supplierOfferDisplayed = await this.supplierOfferService.getEntities(p1);
    let p2 = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
    p2 = p2.and(new Predicate("statusId", FilterQueryOp.Equals, Constants.ThirdPartyContractStatusId.Draft));
    let draftContracts = await this.buyingContractService.getEntities(p2);
    let draftContractToCreate = draftContracts.filter(x => supplierOfferDisplayed.some(y => y.id == x.supplierOfferId));
    if (draftContractToCreate.length == 0) {
      this.logger.LogError(this.i18n.tr("offercomparison.noContractToCreate"), null, null, true);
      return
    }
    for (const draft of draftContractToCreate) {
      await this.contractSupplierOfferVersionApiService.save(this.projectId, draft.supplierOfferId, await ThirdPartyContractDetail.getSupplierTypeId(draft.supplierOfferId));
    }
    toastr.success(this.i18n.tr("offercomparison.successContractCreation"))
  }

  public async getExcelFileName() {
    let title = (await this.projectService.getEntityById(this.projectId)).projectName
    if (this.workQuotationVersionId != null) {
      title += "_" + this.i18n.tr("projectindex.quote")
    }
    else {
      title += "_" + this.i18n.tr("projectindex.buying")
    }
    return title + "_" + this.i18n.tr("offercomparison.offercomparison") + "_" + (await this.requestSupplierOfferService.getEntityById(this.requestSupplierOfferSelected as number)).requestOfferName
  }

  private getCurrentSupplierData(params) {
    let supplierField = params.colDef.colId;
    return params?.data?.supplierPrices?.[supplierField.split('_')[0]];
  }
}
