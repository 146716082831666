import { SubContractorProgressStatementApiService } from 'services/progress-statement-api-service';
import { DataFormat } from 'select2';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, observable } from "aurelia-framework";
import { ActionDialogBoxInputParameters, CustomLogger, DialogBoxViewModel, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, GlobalLoaderService, IMenuGroup, PictureHelper, ServiceBase, Various } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../../constants';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { MeteringTotalPrices } from 'services/i-data-line-api-service';
import { ClientProgressStatementApiService } from 'services/progress-statement-api-service';
import { Predicate, FilterQueryOp } from 'breeze-client';
import moment from 'moment';
import { ValidationRules } from 'aurelia-validation';
import { GenerationApiParams, ReturnParams, SendOffer } from 'projects/quotes/send-offers/send-offer';
import { ReportApiService } from 'services/report-api-service';
import { InvoiceApiService } from 'services/invoice-api-service';
import * as toastr from 'toastr';

@autoinject
export class ProgressStatementDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ProgressStatement> {
  @computedFrom('editingMode', 'entity')
  get documentTitle() {
    if (this.isCreationMode) {
      return '';
    }
    return this.entity.numProgressStatement;
  }
  ressourceName = '';
  projectId: any;
  clientAdditionalWorkId: any;
  subContractorId: any;

  public progressStatementApiService: any;

  priceOfferLinesGrid: PriceOfferLinesGrid;

  private footer: MeteringTotalPrices;
  selectedAllFirstTime: boolean = false;
  private progressStatementVersionService: ServiceBase<Merlin.Web.Model.ProgressStatementVersion>;
  private progressStatementStatusService: EnumerationTypeService;
  private progressStatementVersion: Merlin.Web.Model.ProgressStatementVersion
  @observable
  private selectedProgressStatementVersion: DataFormat
  private allPSVersion: Array<Merlin.Web.Model.ProgressStatementVersion>;
  private isCreatingVersion: boolean = false;
  private project: Merlin.Web.Model.Project;
  private signatoryService: ServiceBase<Merlin.Web.Model.ProgressStatementSignatory>;

  private agGridViewModuleView = "progress-statement";
  private subContractorAgGridViewModuleView = "sub-contractor-progress-statement";
  public screenExpand: boolean;
  public columnVisibleClient: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "totalSellingPriceWithIndirectCost", visibility: false },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "deltaTotal", visibility: true },
      { colId: "absoluteTotal", visibility: true },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: false },
      { colId: "buyingClientValidationDeadlineDate", visibility: false },
      { colId: "buyingClientValidationDate", visibility: false },
      { colId: "deliveryDeadlineDate", visibility: false },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "quantityTypeSupplierTypeId", visibility: true },
      { colId: "totalQuantityDone", visibility: false },
      { colId: "totalQuantityPercentageDone", visibility: false },
      { colId: "isFinalised", visibility: true },
      { colId: "deltaQuantityDone", visibility: true },
      { colId: "deltaQuantityDonePercentage", visibility: true },
      { colId: "absoluteQuantityDone", visibility: true },
      { colId: "absoluteQuantityDonePercentage", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];

  public columnVisibleSubContractor: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: false },
      { colId: "proposedQuantityFormula", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: false },
      { colId: "requestedQuantity", visibility: false },
      { colId: "dataLineDeliveries", visibility: false },
      { colId: "dataLineActivities", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "definedSellingUnitPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "totalBuyingPrice", visibility: false },
      { colId: "totalSellingPrice", visibility: false },
      { colId: "tags", visibility: false },
      { colId: "supplierPrice", visibility: true },
      { colId: "supplierQuantity", visibility: true },
      { colId: "supplierQuantityTypeId", visibility: true },
      { colId: "supplierTotal", visibility: true },
      { colId: "deltaTotal", visibility: true },
      { colId: "absoluteTotal", visibility: true },
      { colId: "supplierComment", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "totalQuantityDone", visibility: true },
      { colId: "totalQuantityPercentageDone", visibility: true },
      { colId: "isFinalised", visibility: true },
      { colId: "deltaQuantityDone", visibility: true },
      { colId: "deltaQuantityDonePercentage", visibility: true },
      { colId: "absoluteQuantityDone", visibility: true },
      { colId: "absoluteQuantityDonePercentage", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];


  private menuItems: Array<IMenuGroup>;
  private projectService: ServiceBase<Merlin.Web.Model.Project>

  constructor(router: Router, logger: CustomLogger, public clientProgressStatementApiService: ClientProgressStatementApiService, public subContractorProgressStatementApiService: SubContractorProgressStatementApiService, private reportApiService: ReportApiService, private invoiceService: InvoiceApiService, private gls: GlobalLoaderService, private fileHelper: PictureHelper) {
    super(router, logger);
    this.progressStatementVersionService = new ServiceBase<Merlin.Web.Model.ProgressStatementVersion>(Constants.EntityTypeNames.ProgressStatementVersion);
    this.progressStatementStatusService = new EnumerationTypeService(Constants.EnumerationTypes.ProgressStatementVersionStatus)
    super.initialize(new ServiceBase<Merlin.Web.Model.ProgressStatement>(Constants.EntityTypeNames.ProgressStatement));
    ValidationRules.customRule('end-date-validation', async (value, obj) => {
      if (value != null && (this.entity.periodStartDate != null ? moment(this.entity.periodStartDate).isBefore(value) : true)) {
        return true;
      }
      return false;
    }, 'progressstatement.endDateError');

    this.signatoryService = new ServiceBase<Merlin.Web.Model.ProgressStatementSignatory>(Constants.EntityTypeNames.ProgressStatementSignatory);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.subContractorId = params.subContractorId;

    if (this.subContractorId == null) {
      this.progressStatementApiService = this.clientProgressStatementApiService;
    }
    else {
      this.progressStatementApiService = this.subContractorProgressStatementApiService;
      this.progressStatementApiService.subContractorId = this.subContractorId
    }
    this.progressStatementApiService.projectId = this.projectId;
    this.progressStatementApiService.progressStatementId = params.progressStatementId;
    this.progressStatementApiService.progressStatementVersionId = params.progressStatementVersionId
    if (params.progressStatementId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      let p = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
      p = p.and(new Predicate("acceptationDate", FilterQueryOp.NotEquals, null));
      if (this.subContractorId != null) {
        p = p.and(new Predicate("subContractorId", FilterQueryOp.Equals, this.subContractorId))
      }
      else {
        p = p.and(new Predicate("subContractorId", FilterQueryOp.Equals, null))
      }
      let previousProgressStatement = (await this.service.getEntities(p)).sortBy('id').last();
      this.entity = await this.service.createEntity(
        {
          projectId: this.projectId,
          periodStartDate: previousProgressStatement != null ? moment(previousProgressStatement.periodEndDate).add(1, "day").toDate() : null,
          subContractorId: this.subContractorId
        });
      this.progressStatementVersion = await this.progressStatementVersionService.createEntity({
        versionId: 1,
        statusId: Constants.ProgressStatementVersionStatusId.InProgress
      });
      this.progressStatementVersion.progressStatement = this.entity;
      this.isCreatingVersion = true;
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(params.progressStatementId);
      await this.loadAllVersions();
      let previousProgressStatementVersion = this.allPSVersion.sort(x => x.versionId).last();

      if (params.progressStatementVersionId != null && params.progressStatementVersionId == -100) { //New Version
        this.progressStatementVersion = await this.progressStatementVersionService.createEntity({
          versionId: previousProgressStatementVersion.versionId + 1,
          statusId: Constants.ProgressStatementVersionStatusId.InProgress
        });
        this.progressStatementVersion.progressStatementId = this.entity.id;
        this.isCreatingVersion = true;
      }
      else {
        let acceptedVersions = this.allPSVersion.filter(x => x.statusId == Constants.ProgressStatementVersionStatusId.Accepted)
        if (acceptedVersions.length > 0) {
          this.progressStatementVersion = acceptedVersions.last();
          this.selectedProgressStatementVersion = acceptedVersions.last().id;
        }
        else {
          this.progressStatementVersion = previousProgressStatementVersion;
          this.selectedProgressStatementVersion = previousProgressStatementVersion.id;
        }
      }
      this.progressStatementApiService.progressStatementVersionId = this.progressStatementVersion.id;

      this.setMenuItems();
      this.project = await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(parseInt(this.projectId));
      await this.getMeteringTotalPrices();
    }
    await super.activate(params);
    ValidationRules
      .ensure('periodEndDate')
      .satisfiesRule('end-date-validation')
      .on(this.entity);
  }

  private async loadAllVersions() {
    let p1 = new Predicate("progressStatementId", FilterQueryOp.Equals, this.entity.id);
    this.allPSVersion = await this.progressStatementVersionService.getEntities(p1, ['status', 'progressStatementSignatories', 'sellingInvoice']);
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  protected async afterSave(): Promise<void> {
    if (this.isCreatingVersion) {
      this.progressStatementApiService.progressStatementVersionId = this.progressStatementVersion.id;
      await this.progressStatementApiService.afterCreateProgressStatement();
      let params;
      if (this.subContractorId != null) {
        params = { progressStatementId: this.entity.id, subContractorId: this.subContractorId }
        this.router.navigateToRoute('progress-statement-subcontractor-detail', params, { replace: true });
      }
      else {
        params = { progressStatementId: this.entity.id }
        this.router.navigateToRoute('progress-statement-detail', params, { replace: true });
      }
    }
    return;
  }

  private async setStatusToVersion(statusId: number) {
    this.progressStatementVersion.statusId = statusId;
    this.progressStatementVersion.status = await this.progressStatementStatusService.getEntityById(statusId);
  }

  private async setMenuItems() {
    if (this.editingMode == EditingModeEnum.Create) {
      this.menuItems = [];
    } else {
      this.menuItems = [
        {
          group: "0",
          hiddenLabel: true,
          items: [
            {
              label: this.i18n.tr("progressstatement.accept"),
              icon: "digi-check",
              handler: async () => {
                await this.setStatusToVersion(Constants.ProgressStatementVersionStatusId.Accepted);
                this.entity.acceptationDate = new Date(Date.now());
                await this.progressStatementApiService.afterValidatingProgressStatement();
                await this.save(true);
                if (await this.questionForInvoiceCreation()) {
                  await this.createInvoice();
                }
              },
              disabled: () => {
                return this.entity.acceptationDate != null
              }
            },
            {
              label: this.i18n.tr("progressstatement.refuse"),
              icon: "digi-close",
              handler: async () => {
                await this.setStatusToVersion(Constants.ProgressStatementVersionStatusId.Disputed);
                await this.save();
              },
              disabled: () => {
                return this.entity.acceptationDate != null
              }
            },
            /*{
              label: this.i18n.tr("progressstatement.send"),
              icon: "digi-mail-send-line",
              handler: async () => {
                // this.entity.acceptationDate = new Date(Date.now());
                // await this.save();
              }
            },*/
            {
              label: this.i18n.tr("menu.delete"),
              icon: "digi-delete-bin-line",
              handler: async () => {
                let deletedLastVersion = await this.progressStatementApiService.deleteCurrentVersion();
                if (deletedLastVersion != null) {
                  if (deletedLastVersion) {
                    this.router.navigateToRoute('progress-statement/all');
                  }
                  else {
                    await this.loadAllVersions();
                    let previousVersion: Merlin.Web.Model.ProgressStatementVersion = this.allPSVersion.last();
                    this.selectedProgressStatementVersion = { id: previousVersion.id, text: previousVersion.versionId.toString() };
                  }
                }
              },
              disabled: () => {
                return this.progressStatementVersion.statusId != Constants.ProgressStatementVersionStatusId.InProgress;
              }
            },
          ]
        },
        {
          group: "1",
          hiddenLabel: true,
          items: [
            {
              label: this.i18n.tr("progressstatement.previewProgressStatement"),
              icon: "digi-search",
              handler: async () => {
                await this.previewProgressStatement();
              },
            },
            {
              label: this.i18n.tr('progressstatement.sendProgressStatement'),
              icon: "digi-mail-send-line",
              handler: () => {
                this.showSendOfferBox();
              }
            }
          ]
        },
        {
          group: "2",
          hiddenLabel: true,
          items: [
            {
              label: this.subContractorId == null ? this.i18n.tr("progressstatement.createInvoice") : this.i18n.tr("progressstatement.createBillingVoucher"),
              icon: "digi-mail-send-line",
              handler: async () => {
                await this.createInvoice();
              },
              disabled: () => {
                return (this.progressStatementVersion.sellingInvoiceId != null && this.progressStatementVersion.sellingInvoice.invoiceStatusId != Constants.SellingInvoiceStatusId.Cancelled && this.progressStatementVersion.statusId == Constants.ProgressStatementVersionStatusId.Accepted)
                  || this.progressStatementVersion.statusId != Constants.ProgressStatementVersionStatusId.Accepted;
              }
            }
          ]
        }
      ];
    }
  }

  getGridMenuItems(params) {
    if (this.isCreationMode) return [];
    return [

    ];
  }

  async selectedProgressStatementVersionChanged(newVal, oldVal) {
    if (newVal != oldVal) {
      if (typeof newVal === "object") {
        newVal = parseInt(newVal.id);
      }
      this.progressStatementApiService.progressStatementVersionId = newVal;
      this.priceOfferLinesGrid?.saveSettingAndReload();
      this.progressStatementVersion = this.allPSVersion.find(x => x.id == newVal);
      await this.getMeteringTotalPrices();
    }
  }

  private showSendOfferBox() {
    this.box.showCustomDialog(
      SendOffer,
      this.progressStatementVersion.id,
      this.i18n.tr('progressstatement.sendProgressStatement'),
      {
        canSave: false,
        size: 'lg',
        model: {
          generationParams: {
            projectTypeId: this.project.projectTypeId,
            projectId: this.projectId,
            companyIds: [],
            coverLetterContentHtml: this.progressStatementVersion.coverLetterContentHtml,
            annexContentHtml: this.progressStatementVersion.annexContentHtml,
            coverLetterContext: this.entity.subContractorId != null ? Constants.ContentTypeId.SubContractorProgressStatementCoverLetter : Constants.ContentTypeId.ClientProgressStatementCoverLetter,
            annexContext: this.entity.subContractorId != null ? Constants.ContentTypeId.SubContractorProgressStatementAnnex : Constants.ContentTypeId.ClientProgressStatementAnnex,
            emailContext: this.entity.subContractorId != null ? Constants.EmailContextId.SubContractorProgressStatement : Constants.EmailContextId.ClientProgressStatement,
            languageId: this.project.communicationLanguageId,
            signatoryIds: this.progressStatementVersion.progressStatementSignatories.map(x => x.signatoryUserId),
            previewRequestParams: {
              projectId: this.projectId,
              thirdPartyId: this.project.mainThirdPartyId,
              priceOfferVersionId: this.entity.id
            },
            afterStep2: async (data: ReturnParams) => {
              await this.handleGenerationReturnParams(data);
            },
            items: [
              {
                id: 1,
                name: this.i18n.tr("priceofferversion.pdf"),
                items: [
                  {
                    id: 1,
                    icon: "digi-file-sync",
                    title: this.i18n.tr("priceofferversion.generate"),
                    description: this.i18n.tr("priceofferversion.pfdHasInternalModel"),
                    selected: true,
                    disabled: false,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getProgressStatementPdf(this.progressStatementVersion.id, data.selectedRefToDisplay);
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    ).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        if (this.progressStatementVersion.statusId == Constants.ProgressStatementVersionStatusId.InProgress) {
          await this.setStatusToVersion(Constants.ProgressStatementVersionStatusId.Sent);
        }
        await this.handleGenerationReturnParams(result.output);
        //await this.save(true);
      }
    });
  }
  async handleGenerationReturnParams(data: ReturnParams) {
    this.progressStatementVersion.annexContentHtml = data.annexContentHtml;
    this.progressStatementVersion.coverLetterContentHtml = data.coverLetterContentHtml;
    this.service.saveEntity(this.progressStatementVersion, true);

    if (this.progressStatementVersion.progressStatementSignatories.length > 0)
      await this.signatoryService.deleteEntities(this.progressStatementVersion.progressStatementSignatories, false, null, true);

    data.signatoryIds.forEach(async (signatoryId) => {
      this.progressStatementVersion.progressStatementSignatories.push(await this.signatoryService.createEntity({ signatoryUserId: signatoryId, progressStatementVersionId: this.progressStatementVersion.id }));
    });
    await this.signatoryService.saveEntities(this.progressStatementVersion.progressStatementSignatories, true);
  }
  private async questionForInvoiceCreation(): Promise<boolean> {
    let result: boolean = false;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.subContractorId == null ? this.i18n.tr("progressstatement.createInvoiceQuestion") : this.i18n.tr("progressstatement.createBillingVoucherQuestion"), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
      async (resultQuestion) => {
        result = resultQuestion.output
      }
    )
    return result;
  }
  private async reloadCurrent() {
    this.allPSVersion.splice(0);
    this.progressStatementVersion = null;
    await this.loadAllVersions();
    let acceptedVersions = this.allPSVersion.filter(x => x.statusId == Constants.ProgressStatementVersionStatusId.Accepted)
    if (acceptedVersions.length > 0) {
      this.progressStatementVersion = acceptedVersions.last();
    }
    else {
      let previousProgressStatementVersion = this.allPSVersion.sort(x => x.versionId).last();
      this.progressStatementVersion = previousProgressStatementVersion;
    }
  }
  @computedFrom("progressStatementVersion.id")
  get getInvoiceLabel(): string {
    return moment(this.progressStatementVersion.sellingInvoice.invoiceDate).format("DD/MM/YYYY") + " - " + (this.progressStatementVersion.sellingInvoice.invoiceStatusId == Constants.SellingInvoiceStatusId.Draft ? this.i18n.tr("sellinginvoice.draft") : this.progressStatementVersion.sellingInvoice.invoiceNumber)
  }
  public goToInvoice() {
    window.open(`projects/${this.entity.projectId}/invoicing/invoices/${this.progressStatementVersion.sellingInvoiceId}?projectId=${this.entity.projectId}`, "_blank")
  }
  public async createInvoice() {
    let result = await this.invoiceService.createFromProgressStatement(this.progressStatementVersion.id);
    if (result) {
      toastr.success(this.subContractorId == null ? this.i18n.tr("sellinginvoice.invoiceCreatedSuccessfully") : this.i18n.tr("sellinginvoice.billingVoucherCreatedSuccessfully"))
    }
    await this.reloadCurrent();
  }
  private async previewProgressStatement() {
    this.gls.allow();
    var result = await this.reportApiService.getPreviewProgressStatementPdf(this.progressStatementVersion.id);
    this.fileHelper.previewFile(result);
  }
  async getMeteringTotalPrices() {
    this.footer = await this.progressStatementApiService.getTotals();
  }
  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.realization") + "_" + (this.subContractorId == null ? this.i18n.tr("progressstatement.progressStatementList") : this.i18n.tr("progressstatement.progressStatementSubContractorList")) + "_" + this.entity.numProgressStatement
  }
}
