import { autoinject, bindable, BindingEngine, customElement, TaskQueue } from "aurelia-framework";
import { ColumnVisible } from "module-list-tree-data/module-list-tree-data";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { AmendmentApiService } from "services/amendment-api-service";

@autoinject
@customElement("amendment-step-1")
export class AmendmentStep1 {
  public linesGrid: PriceOfferLinesGrid;
  public getGridMenuItems = () => [];
  public canEditCell() { return false; };
  private agGridViewModuleView = "amendment-step-1";
  private selectedAllFirstTime: boolean = false
  @bindable
  private selectedLines: Array<any> = [];

  @bindable
  private projectId: number;

  @bindable
  private contractId: number;

  private rowToOpen: number[] = [];

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "totalSellingPriceWithIndirectCost", visibility: false },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: true },
      { colId: "buyingClientValidationDeadlineDate", visibility: true },
      { colId: "buyingClientValidationDate", visibility: true },
      { colId: "deliveryDeadlineDate", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "contractNum", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];

  constructor(private amendmentApiService: AmendmentApiService, private taskQueue: TaskQueue, private bindingEngine: BindingEngine) {

  }



  activate(params) {
    this.amendmentApiService.contractId = this.contractId;
    this.amendmentApiService.projectId = this.projectId;
  }

  async attached() {
    if (this.linesGrid == null) {
      this.bindingEngine.propertyObserver(this, 'linesGrid').subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) this.observablePriceOfferLinesGrid();
      })
    } else {
      this.observablePriceOfferLinesGrid();
    }
  }

  private getSelectedLines() {
    this.selectedLines = this.amendmentApiService?.dataLines?.filter(line => line.isSelected);
  }

  observablePriceOfferLinesGrid() {

    if (this.linesGrid.listTreeData != null) {
      this.observableListTreeData()
    }
    this.bindingEngine.propertyObserver(this.linesGrid, "listTreeData").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && this.linesGrid?.listTreeData != null) {
        this.observableListTreeData()
      }
    });
    this.bindingEngine.propertyObserver(this.linesGrid, "gridOptions").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && this.linesGrid.gridOptions != null) {
        this.linesGrid.gridOptions.onSelectionChanged = (event) => { this.getSelectedLines() };
        this.getSelectedLines();
      }
    });
  }

  observableListTreeData() {
    this.bindingEngine.propertyObserver(this.linesGrid.listTreeData, "dataLines").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && !this.selectedAllFirstTime) {
        this.linesGrid.listTreeData.dataLines.forEach(line => line.isSelected = true);
        this.amendmentApiService.dataLines = this.linesGrid.listTreeData.dataLines;
        this.selectedAllFirstTime = true;
      }
    });
  }


}
