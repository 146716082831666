import { autoinject, bindable, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { SummaryByActivityOrMonth } from "services/dashboard-service";

@autoinject
export class SummaryLine {

  @bindable
  public line: SummaryByActivityOrMonth;
  @bindable
  public showMargin: boolean = true;
  @bindable
  public nbDecimalForPriceDisplay: number;
  i18n: I18N;

  /**
   *
   */
  constructor() {
    this.i18n = Container.instance.get(I18N);
  }
  getLabel() {
    return this.line.label ?? this.i18n.tr('priceofferversionsummary.activityEmpty')
  }
}
