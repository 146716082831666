import { DashboardService } from './../../services/dashboard-service';
import { RealizationApiService } from './../../services/realization-api-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ChartPieSerie, MonthlyExecutionChartData, MonthlyExecutionChartDataMapper } from 'resources/charts/chart-mappers';
import { I18N } from 'aurelia-i18n';
import { FloatingDate } from './floating-date/floating-date';
import moment from 'moment';
import { Box, ServiceBase } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { ChartProject } from './chart-project/chart-project';



@autoinject
export class RealizationSummary {
  projectId: any;
  protected monthlyExecutionChartData: MonthlyExecutionChartData;
  private progressStatementStatusDTO: ProgressStatementStatusDTO;
  private realizationDate: RealizationDateDTO;
  private taskStatus: TaskStatusDTO;
  private nbDecimalForPriceDisplay = 2;
  private monthlyExecutionChartConfigDataMapper = new MonthlyExecutionChartDataMapper();
  private totalIncome: number;
  private totalExpense: number;
  constructor(public router: Router, private i18n: I18N, private box: Box, private api: RealizationApiService, private dashboardService: DashboardService) {

  }

  public async activate(params: any) {
    this.projectId = params.projectId;
    this.taskStatus = await this.api.getTasksStatus(this.projectId);
    await this.loadRealizationDate();
    this.configMonthlyExecutionChart();
    this.progressStatementStatusDTO = await this.dashboardService.getProgressStatementOverview(this.projectId);
    this.nbDecimalForPriceDisplay = (await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(this.projectId)).nbDecimalForPriceDisplay;
  }

  private async configMonthlyExecutionChart() {
    let data = await this.dashboardService.getProgressStatementByMonth(this.projectId, moment().add(-2, 'M').toDate(), moment().toDate());
    if (data == null) return;
    this.totalIncome = data.totalIncome;
    this.totalExpense = data.totalExpense;
    this.monthlyExecutionChartData = this.monthlyExecutionChartConfigDataMapper.mapData(data.executionChartDTO, this.i18n);
  }

  private async loadRealizationDate() {
    this.realizationDate = await this.api.getRealizationDateDTO(this.projectId);
  }

  openFloatingDate() {
    this.box.showFloatingBox(new FloatingDate(this.realizationDate, this.projectId), false, false).whenClosed(async (result) => {
      await this.loadRealizationDate();
    });
  }
  openAllChart() {
    this.box.showFloatingBox(new ChartProject(this.projectId, this.dashboardService, this.i18n, null, this.nbDecimalForPriceDisplay), false, false);
  }

  goToTask() {
    this.router.navigate(`/projects/${this.projectId}/module-tasks`);
  }
  goToInvoice() {
    this.router.navigate(`/projects/${this.projectId}/invoicing/invoices/all`);
  }
  goToPsClient() {
    this.router.navigate(`/projects/${this.projectId}/realization/progress-statement/all`);
  }
  goToPsSubContractor() {
    this.router.navigate(`/projects/${this.projectId}/realization/progress-statement-subcontractor/all`);
  }
  goToAdditionalWork() {
    this.router.navigate(`/projects/${this.projectId}/realization/client-additional-work/all`);
  }
}

export interface RealizationDateDTO {
  contractualEndDate: Date | string; // Date de fin de chantier contractuelle
  projectedEndDate: Date | string; // Date de fin de chantier projetée
  remainingContractualDays: number; // Nombre de jours contractuels restants
  exceededDays: number; // Nombre de jours de dépassement
  weatherDelayDays: number; // Jours d'intempéries
  approvedStopDays: number; // Jours d'arrêts validés 
  addedDaysFromApprovedAdditionalWork: number; // Jours ajoutés suite à décomptes "Accepté"
}

export interface TaskStatusDTO {
  nbLateTask: number | null;
  nbUrgentTask: number | null;
  nbSoonTask: number | null;
}
export interface ProgressStatementStatusDTO {
  chartPieSeries: ChartPieSerie[];
  nbProgressStatementSent: number;
  psSentSum: number;
  nbProgressStatementInvoiced: number;
  psInvoiceSum: number;
  nbProgressStatementPaid: number;
  psPaidSum: number;
  nbProgressStatementValidated: number;
  psValidatedSum: number;
  nbSubContractorProgressStatementNotValidated: number;
  nbAdditionalWorkNeedValidation: number;
  totalAmountAdditionalWork: number;
}
