import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from "aurelia-framework";
import { Box, CustomLogger, EditingModeEnum, EntityDetailViewModelBase, IMenuGroup, ServiceBase } from "digiwall-lib";
import { FloatingNumber } from "./floating-number/floating-number";
import { FloatingIntern } from "./floating-intern/floating-intern";
import { FloatingDate } from "./floating-date/floating-date";
import { FloatingPartners } from "./floating-partners/floating-partners";
import { FloatingExtern } from "./floating-extern/floating-extern";
import { FloatingAdress } from "./floating-adress/floating-adress";
import { FloatingCompany } from "./floating-company/floating-company";
import { FloatingDescription } from "./floating-description/floating-description";
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { Router } from 'aurelia-router';
import { ProjectApiService } from "services/project-api-service";
import moment = require('moment');
import { MonthlyExecutionChartData, MonthlyExecutionChartDataMapper } from 'resources/charts/chart-mappers';
import { DashboardService } from 'services/dashboard-service';
import { ImportManager } from 'resources/utilities/import-manager';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class ProjectResume extends EntityDetailViewModelBase<Merlin.Web.Model.Project> {
  public ressourceName: string = Constants.EntityTypeNames.Project;

  projectResumeBase: ProjectResumeBase;
  projectResumeAddress: ProjectResumeAddress;
  projectResumeCompany: ProjectResumeCompany;
  projectResumeDate: ProjectResumeDates;
  projectResumeDescription: ProjectResumeDescription;
  projectResumeExtern: ProjectResumeExtern;
  projectResumeIntern: ProjectResumeIntern;
  projectResumeNumber: ProjectResumeNumber;
  projectResumePartner: ProjectResumePartner;
  protected monthlyExecutionChartData: MonthlyExecutionChartData;
  private monthlyExecutionChartConfigDataMapper = new MonthlyExecutionChartDataMapper();
  extern: string;
  partners: string;
  private projectId: number
  private projectStarted: boolean = false;

  private priceOfferService: ServiceBase<Merlin.Web.Model.PriceOfferVersion>;
  private workQuotationService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  private parameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;

  private menuItems: Array<IMenuGroup>;

  private BACK_UP_URL = Constants.Asset.PLACEHOLDER_IMAGE;

  constructor(router: Router, logger: CustomLogger, private projectApiService: ProjectApiService, private dialogController: DialogController, private dashboardService: DashboardService, public importManager: ImportManager) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project));
    this.priceOfferService = new ServiceBase<Merlin.Web.Model.PriceOfferVersion>(Constants.EntityTypeNames.PriceOfferVersion);
    this.workQuotationService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
    this.parameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
  }
  async activate(params: any): Promise<void> {
    await super.activate(params);
    this.projectId = params.projectId;
    await this.getBaseInfo(this.projectId);
    await this.getEntity(this.projectId);
    await this.getAddress(this.projectId);
    await this.getCompany(this.projectId);
    await this.getDates(this.projectId);
    await this.getResumeExtern(this.projectId);
    await this.getResumeIntern(this.projectId);
    await this.getResumePartner(this.projectId);
    await this.getResumeNumber(this.projectId);
    await this.configMonthlyExecutionChart();

    this.projectStarted = (await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(this.projectId)).projectStarted

    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: () => {
              this.navigateToEditProject();
            }
          },
          {
            label: this.i18n.tr("priceoffer.import"),
            icon: "digi-file-download-line",
            handler: () => {
              this.goToImport();
            }
          }
        ]
      }
    ];
  }

  async goToImport() {
    let p1 = new Predicate("projectId", FilterQueryOp.Equals, this.entity.id);
    p1 = p1.and(new Predicate("isCurrentVersion", FilterQueryOp.Equals, true))
    let currentWorkQuotation = (await this.workQuotationService.getEntities(p1))[0];

    let newPriceOfferVersion = await this.priceOfferService.createEntity();
    newPriceOfferVersion.workQuotationVersionId = currentWorkQuotation.id;
    newPriceOfferVersion.priceOfferStatusId = Constants.PriceOfferStatusId.Open;

    let parameter: Merlin.Web.Model.ApplicationParameter = (await this.parameterService.getEntities())[0];
    newPriceOfferVersion.validUntilDate = moment().add(parameter.clientPriceOfferValidityDelayNbDays, 'days').toDate();

    await this.priceOfferService.saveEntity(newPriceOfferVersion, true);

    await this.importManager.importFile(Constants.ImportContext.PriceOffer, newPriceOfferVersion.id, null, null, this.router, {
      "context": Constants.ImportContext.PriceOffer,
      "customParameter": newPriceOfferVersion.id,
      "projectId": this.projectId,
      "errorView": "projects/quotes/import-metering-offer-error-table.html",
      configView: "projects/quotes/metering-config.html",
      sessionId: null,
      importMode: null,
      fileSessionId: null,
      disclaimerView: "projects/quotes/metering-import-disclaimer.html",
      importStep6View: "projects/quotes/import-step-6.html",
      lastStepFooterView: "projects/quotes/last-step-footer-view.html",
      callbackUrl: `/projects/${this.projectId}/quote/price-offer-version-detail/${newPriceOfferVersion.id}`
    });
  }

  private async getEntity(projectId): Promise<any> {
    this.entity = await this.service.getEntityById(projectId, 'projectType');
    this.projectResumeDescription = {
      description: this.entity.projectDescriptionMultiline,
      projectType: this.entity.projectType?.denomination._translation
    }
  }

  private async getBaseInfo(projectId): Promise<any> {
    this.projectResumeBase = await this.projectApiService.getBaseInfo(projectId);
  }

  private async getAddress(projectId): Promise<any> {
    this.projectResumeAddress = await this.projectApiService.getAddress(projectId);
  }

  private async getCompany(projectId): Promise<any> {
    this.projectResumeCompany = await this.projectApiService.getCompany(projectId);
  }
  private async getDates(projectId): Promise<any> {
    this.projectResumeDate = await this.projectApiService.getDates(projectId);
  }
  private async getResumeExtern(projectId): Promise<any> {
    this.projectResumeExtern = await this.projectApiService.getResumeExtern(projectId);
    this.getExtern()
  }
  private async getResumeIntern(projectId): Promise<any> {
    this.projectResumeIntern = await this.projectApiService.getResumeIntern(projectId);
  }
  private async getResumePartner(projectId): Promise<any> {
    this.projectResumePartner = await this.projectApiService.getResumePartner(projectId);
    this.getPartners();
  }
  private async getResumeNumber(projectId): Promise<any> {
    this.projectResumeNumber = await this.projectApiService.getNumber(projectId);
  }
  @computedFrom('editingMode', 'entity.projectName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("project.project");
    }
    else {
      return this.entity.projectName;
    }
  }

  public navigateToEditProject() {
    let url = this.router.generate("project-detail");
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectInternalTeam() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.InternalTeam
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectExternalTeam() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.ExternalTeam
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectPartner() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.ProjectPartners
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  openFloatingNumber() {
    //this.box.showFloatingBox(new FloatingNumber(this.navigateToEditProject, this.router), false, false);
    if (this.projectStarted)
      this.router.navigateToRoute("realization");
  }
  openFloatingIntern() {
    this.box.showFloatingBox(new FloatingIntern(this.projectResumeIntern, this.navigateToEditProjectInternalTeam, this.router), false, false);
  }
  openFloatingDate() {
    this.box.showFloatingBox(new FloatingDate(this.projectResumeDate, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingPartners() {
    this.box.showFloatingBox(new FloatingPartners(this.projectResumePartner, this.navigateToEditProjectPartner, this.router), false, false);
  }
  openFloatingExtern() {
    this.box.showFloatingBox(new FloatingExtern(this.projectResumeExtern, this.navigateToEditProjectExternalTeam, this.router), false, false);
  }
  openFloatingAdress() {
    this.box.showFloatingBox(new FloatingAdress(this.projectResumeAddress, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingCompany() {
    this.box.showFloatingBox(new FloatingCompany(this.projectResumeCompany, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingDescription() {
    this.box.showFloatingBox(new FloatingDescription(this.projectResumeDescription, this.navigateToEditProject, this.router), false, false);
  }

  getPartners() {
    this.partners = "";
    this.projectResumePartner.persons.forEach(person => {
      if (this.partners.length != 0) {
        this.partners += ", ";
      }
      this.partners += person.name;
    })
  }

  getExtern() {
    this.extern = "";
    this.projectResumeExtern.persons.forEach(person => {
      if (this.extern.length != 0) {
        this.extern += ", ";
      }
      this.extern += person.name;
    })
  }

  navigateToThirdParty() {
    window.open(`/third-parties/${this.entity.mainThirdPartyId}`, "_blank")
  }

  @computedFrom("projectResumeDate.offerDeadlineDate")
  get getUtcOfferDeadlineDate() {
    return moment.utc(this.projectResumeDate.offerDeadlineDate).local().format("DD/MM/YYYY HH:mm");
  }

  private async configMonthlyExecutionChart() {
    let data = await this.dashboardService.getProgressStatementByMonth(this.projectId, moment().add(-2, 'M').toDate(), moment().toDate());
    if (data == null) return;
    //this.totalIncome = data.totalIncome;
    //this.totalExpense = data.totalExpense;
    this.monthlyExecutionChartData = this.monthlyExecutionChartConfigDataMapper.mapData(data.executionChartDTO, this.i18n);

  }
}

export interface ProjectResumeBase {
  projectId: number;
  companyName: string;
  projectName: string;
  logoUrl: string;
  status: string;
  statusTextColor: string;
  statusBackgroundColor: string;
  language: string;
  refClient: string;
}

export class ProjectResumeAddress {
  street: string;
  localityName: string;
  zipCode: string;
  countryName: string;
  addressType: string;

  fullAddress: string;
  latitude: number | null;
  longitude: number | null;
}

export class ProjectResumeCompany {
  hasTemporaryCompany: boolean;
  temporaryCompanyName: string;
  partner: string;
  vatNumber: string;
  address: string;
  contactPersons: Array<ProjectResumePerson>;
}
export class ProjectResumePerson {
  name: string;
  role: string;
  phoneNumber: string;
  email: string;
}
export interface ProjectResumeDates {
  plannedWorkingSiteStartingDate: Date | string | null;
  workingSiteStartingDate: Date | string | null;
  estimatedProjectDurationInMonth: number | null;
  offerDeadlineDate: Date | string | null;
}
export class ProjectResumeDescription {
  projectType: string;
  description: string;
}
export class ProjectResumeExtern {
  persons: Array<ProjectResumePerson>;
}
export class ProjectResumeIntern {
  persons: Array<ProjectResumeInternalPerson>;
}
export class ProjectResumeInternalPerson extends ProjectResumePerson {
  avatarUrl: string;
}
export class ProjectResumeNumber {
  signedOffer: number;
  lastOfferPrice: number | null;
  totalSurface: number;
  priceToSquareMeter: number | null;
  validatedCounts: number | null;
  validatedSupplements: number | null;
}
export class ProjectResumePartner {
  persons: Array<ProjectResumePerson>;
}

export interface PrecisionParameter {
  nbDecimalForQuantityDisplay: number;
  nbDecimalForPriceDisplay: number;
}
